@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Modal
/// @group Dialogs
/// @name Modal
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-modal {
  @include cv.cssvars(
    (
      modal-background-color: cv.getcssvar($name: 'scheme-rgba80'),
      modal-container-top: 5rem,
      modal-container-width: 31rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: modal-background-color);
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.#{config.$framework-prefix}-modal-container {
  left: calc(50% - calc(#{cv.getcssvar($name: modal-container-width)} / 2));
  position: absolute;
  top: cv.getcssvar($name: modal-container-top);
  width: cv.getcssvar($name: modal-container-width);

  &::after {
    bottom: calc(-1 * #{cv.getcssvar($name: modal-container-top)});
    content: '';
    height: 1px;
    position: absolute;
    width: 1px;
  }
}
