@use 'sass:map';
@use '../helpers/css-variables';

// TODO: Some of these variables are directly defined as css-variables, we want to keep the amount
// of SCSS variables at an absolute minimum. If we do add SCSS variables, make sure they are
// exported globally ONLY if you really need them globally. use selective @forwarding

/* Core */
@include css-variables.cssvars(
  (
    root-size: 16px,
  )
);

/* Typography - Base */
@include css-variables.cssvars(
  (
    font-family: 'Cera Pro',
  )
);

/* Typography - Font Weights */
$typography-weights: (
  weight-regular: 400,
  weight-medium: 500,
  weight-bold: 700,
);
@include css-variables.cssvars($typography-weights);

/* Typography - Font Size */
$typography-sizes: (
  size-7: 5.9375rem,
  size-6: 3.8125rem,
  size-5: 3.0625rem,
  size-4: 2.4375rem,
  size-3: 1.9375rem,
  size-2: 1.5625rem,
  size-1: 1.25rem,
  size-05: 1.125rem,
  size-0: 1rem,
  size-m1: 0.875rem,
  size-m2: 0.6875rem,
);
@include css-variables.cssvars($typography-sizes);

/* Typography - Line Height */
$line-heights: (
  line-height-l: 1.5,
  line-height-m: 1.375,
  line-height-s: 1.25,
  line-height-xs: 1.125,
);
@include css-variables.cssvars($line-heights);

/* Typography - Letter Spacing */
@include css-variables.cssvars(
  (
    letter-spacing-xxl: 0.1em,
    letter-spacing-xl: 0.0825em,
    letter-spacing-l: 0.075em,
    letter-spacing-m: 0.05em,
    letter-spacing-s: 0.0375em,
    letter-spacing-xs: 0.025em,
    letter-spacing-xxs: 0.0125em,
  )
);
