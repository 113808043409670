@import '~@lego/klik/src/klik';

// Media query breakpoints
$medium-bp: 592px;
$large-bp: 1024px;
$extra-large-bp: 1440px;
$full-width-bp: 1632px;

body {
  --klik-body-background-color: #f8f8f8; // Same color as footer
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d6d6d5;
}

.klik-header {
  --klik-header-background-color: var(--klik-color-light-blue-100);
}

.klik-modal-container {
  --klik-modal-container-width: auto;
  --klik-modal-container-top: var(--klik-space-4);
  padding: 0 var(--klik-space-4);

  @media (min-width: $medium-bp) {
    --klik-modal-container-width: 750px;
    padding: 0;
  }
}

.klik-overlay {
  --klik-overlay-background-color: rgba(var(--klik-scheme-rgb), 0.6);
}

// To fix minor bug within the Klik library
.klik-checkbox-shape,
.klik-radio-shape {
  flex-shrink: 0;
}

.klik-radio {
  margin-top: var(--klik-space-7);
  width: 100%;
}

.klik-header-name {
  font-size: 14px;
  @media (min-width: $medium-bp) {
    font-size: 16px;
  }
  @media (min-width: $large-bp) {
    font-size: 20px;
  }
}

.klik-header-logo {
  margin-right: 4px;
  @media (min-width: $large-bp) {
    margin-right: 24px;
  }
}
