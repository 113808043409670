/* eslint-disable */
@use 'sass:map';
@use '../config' as config;
@use '../helpers/css-variables' as cv;

@mixin theme() {
  // prettier-ignore
  @include cv.cssvars(
    (
      dark-canary: true,
      // Scheme colors

      scheme-main: cv.getcolor($name: 'black'),
      scheme-02: cv.getcolor($name: 'slate-100'),
      scheme-05: cv.getcolor($name: 'slate-90'),
      scheme-10: cv.getcolor($name: 'slate-80'),
      scheme-20: cv.getcolor($name: 'slate-base'),
      scheme-30: cv.getcolor($name: 'slate-60'),
      scheme-40: cv.getcolor($name: 'slate-50'),
      scheme-50: cv.getcolor($name: 'slate-40'),
      scheme-60: cv.getcolor($name: 'slate-30'),
      scheme-base: cv.getcolor($name: 'slate-20'),
      scheme-80: cv.getcolor($name: 'slate-10'),
      scheme-90: cv.getcolor($name: 'slate-05'),
      scheme-100: cv.getcolor($name: 'slate-02'),
      scheme-rgb: cv.getRGB(map.get(
          $map: config.$neutral-colors,
          $key: 'slate-02',
        )),

      // Primary color
      primary-alt-color: cv.getcolor($name: 'light-blue-50'),
      primary-alt-color-invert: cv.getcolor($name: 'white'),

      // Base colors
      border: cv.getcolor($name: 'slate-80'),
      border-hover: cv.getcolor($name: 'slate-60'),
      border-bis: cv.getcolor($name: 'slate-90'),
      border-ter: cv.getcolor($name: 'slate-100'),
      text: cv.getcolor($name: 'slate-02'),

      // States
      focus-inner-color: cv.getcolor($name: 'black'),
    ),
    $is-global-scope: false
  );

  // Component specific
  .#{config.$framework-prefix}-button {
    // prettier-ignore
    @include cv.cssvars(
      (
        button-outline-border-color: cv.getcssvar($name: 'primary-50'),
        button-outline-color: cv.getcssvar($name: 'primary-50'),
        button-outline-hover-color: cv.getcssvar($name: 'primary-40'),
        button-outline-focus-color: cv.getcssvar($name: 'primary-40'),
      ),
      $is-global-scope: false
    );
  }

  .#{config.$framework-prefix}-pagination {
    // prettier-ignore
    @include cv.cssvars(
      (
        item-hover-background-color: cv.getcssvar($name: 'primary-100'),
      ),
      $is-global-scope: false
    );
  }

  .#{config.$framework-prefix}-dropdown {
    // prettier-ignore
    @include cv.cssvars(
      (
        dropdown-item-even-background-color: cv.getcssvar($name: 'primary-100'),
      ),
      $is-global-scope: false
    );
  }

  // Inverting the colors
  @each $name in map.keys(config.$utility-colors) {
    @include cv.cssvars(
      (
        status-#{$name}-base: cv.getcolor($name: 'utility-#{$name}-dark'),
        status-#{$name}-alt: cv.getcolor($name: 'utility-#{$name}-base'),
        status-#{$name}-ter: cv.getcolor($name: 'utility-#{$name}-light')
      ),
      $is-global-scope: false
    );
  }
}
