@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Pagination
/// @group Navigation
/// @name Pagination
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-pagination {
  // prettier-ignore
  @include cv.cssvars(
    (
      item-color: cv.getcssvar($name: scheme-100),
      item-dimensions: 2em,
      item-font-weight: cv.getcssvar($name: 'weight-medium'),
      item-padding: 0.5em,
      icon-spacing: 0.5em,

      // State: hover
      item-hover-background-color:cv.getcssvar('primary-05'),

      // State: active
      item-active-background-color:cv.getcssvar('primary-05'),

      // State: is icon
      item-icon-font-weight:cv.getcssvar('weight-regular'),

    ),
    $is-global-scope: false
  );

  align-items: center;
  display: flex;
  justify-content: space-between;

  &.#{config.$framework-prefix}-is-centered {
    justify-content: center;

    .#{config.$framework-prefix}-pagination-results,
    .#{config.$framework-prefix}-pagination-list {
      justify-content: center;
    }
  }

  &.#{config.$framework-prefix}-is-stacked {
    align-items: stretch;
    flex-direction: column;

    .#{config.$framework-prefix}-pagination-results,
    .#{config.$framework-prefix}-pagination-list {
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }
  }
}

.#{config.$framework-prefix}-pagination-list {
  display: flex;
}

.#{config.$framework-prefix}-pagination-item {
  display: flex;
  list-style: none;
  margin: 0 0.125em;

  > a,
  > button {
    @extend %reset;
    @extend %unselectable;

    align-items: center;
    background-color: cv.getcssvar($name: item-background-color);
    border-radius: cv.getcssvar($name: 'radius-rounded');
    color: transparent;
    cursor: pointer;
    display: flex;
    font-weight: cv.getcssvar($name: item-font-weight);
    justify-content: center;
    line-height: 1;
    min-height: cv.getcssvar($name: item-dimensions);
    min-width: cv.getcssvar($name: item-dimensions);
    padding: cv.getcssvar($name: item-padding);
    position: relative;
    text-align: center;

    &::after {
      align-items: center;
      color: cv.getcssvar($name: item-color);
      content: attr(data-text);
      display: flex;
      font-weight: cv.getcssvar($name: 'weight-regular');
      justify-content: center;
      left: cv.getcssvar($name: item-padding);
      position: absolute;
      right: cv.getcssvar($name: item-padding);
      text-align: center;
    }

    &:hover {
      @include cv.cssvar(item-background-color, cv.getcssvar($name: 'item-hover-background-color'));
    }
  }

  &.#{config.$framework-prefix}-is-active {
    > a,
    > button {
      @include cv.cssvar(item-background-color, cv.getcolor($name: 'item-active-background-color'));

      color: cv.getcssvar($name: item-color);

      &::after {
        color: transparent;
      }
    }
  }

  // TODO: This focus state is a showcase for focusing pagination with keyboard controls
  // when the designs are in place this should be replaced.
  & > button:focus {
    box-shadow: cv.getcssvar($name: focus-box-shadow);
  }

  &.#{config.$framework-prefix}-is-icon {
    > a,
    > button {
      @include cv.cssvar(item-font-weight, cv.getcssvar($name: 'item-icon-font-weight'));

      color: cv.getcssvar($name: item-color);

      .#{config.$framework-prefix}-icon {
        font-size: 0.75em;

        &:first-child {
          margin-right: cv.getcssvar($name: icon-spacing);
        }

        &:last-child {
          margin-left: cv.getcssvar($name: icon-spacing);
        }
      }

      &::after {
        display: none;
      }
    }
  }
}

.#{config.$framework-prefix}-pagination-results {
  align-items: center;
  display: flex;

  .#{config.$framework-prefix}-input.#{config.$framework-prefix}-is-select {
    flex-grow: 0;
    flex-shrink: 0;
    width: auto;
  }
}

.#{config.$framework-prefix}-pagination-results-label {
  margin-right: 0.75em;
  white-space: nowrap;
}
