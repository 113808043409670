@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Divider
/// @group Divider
/// @name Divider
/// @since 0.0.1
/// @author Jeremy Thomas

.#{config.$framework-prefix}-divider {
  // prettier-ignore
  @include cv.cssvars(
    (
      divider-background-color: cv.getcssvar($name: 'border-ter'),
      divider-dimensions: 0.125rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: 'divider-background-color');
  border: none;
  height: cv.getcssvar($name: 'divider-dimensions');

  &.#{config.$framework-prefix}-is-vertical {
    height: 100%;
    width: cv.getcssvar($name: 'divider-dimensions');
  }
}
