.Toastify__toast {
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
  background: white;
  color: #2f2f2e;
}

.Toastify__toast--info {
  border: 1px solid #179fe3;
  border-left-width: 12px;
}
.Toastify__toast--success {
  border: 1px solid #1cb20c;
  border-left-width: 12px;
}
.Toastify__toast--warning {
  border: 1px solid #f2a705;
  border-left-width: 12px;
}
.Toastify__toast--error {
  border: 1px solid #db1f1f;
  border-left-width: 12px;
}
