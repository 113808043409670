@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Text Link
/// @group Typography
/// @name Text Link
/// @since 0.0.1
/// @author Jeremy Thomas
a.#{config.$framework-prefix}-text-link,
div.#{config.$framework-prefix}-text-link a {
  // prettier-ignore
  @include cv.cssvars(
    (
      text-link-color: cv.getcssvar($name: 'primary-base'),
      text-link-font-size: cv.getcssvar('size-0'),
      text-link-line-height: 1.375rem,
      text-link-icon-dimensions: 0.875rem,

      // State: hover
      text-hover-link-color: cv.getcssvar($name: 'primary-alt-color'),

      // State: focus
      text-focus-link-border-radius: 0.125em,
      text-focus-link-box-shadow: cv.getcssvar($name: focus-box-shadow),

      // State: active
      text-active-link-color: cv.getcssvar($name: 'primary-alt-color'),

      // Size: small
      text-small-link-font-size: cv.getcssvar('size-m1'),
      text-small-link-line-height: 1.25rem,
      text-small-link-icon-dimensions: 0.75rem,

      // Size: large
      text-large-link-font-size: cv.getcssvar('size-05'),
      text-large-link-line-height: 1.75rem,
      text-large-link-icon-dimensions: 1rem,
    ),
    $is-global-scope: false
  );

  border-radius: cv.getcssvar($name: 'text-link-border-radius');
  box-shadow: cv.getcssvar($name: 'text-link-box-shadow');
  color: cv.getcssvar($name: 'text-link-color');
  cursor: pointer;
  font-size: cv.getcssvar($name: 'text-link-font-size');
  font-weight: cv.getcssvar($name: 'text-link-font-weight');
  line-height: cv.getcssvar($name: 'text-link-line-height');
  outline: none;
  text-decoration: underline;

  &:hover,
  &.#{config.$framework-prefix}-is-hover {
    @include cv.cssvar(text-link-color, cv.getcssvar($name: 'text-hover-link-color'));
  }

  &:focus,
  &.#{config.$framework-prefix}-is-focus {
    &:not(:active) {
      @include cv.cssvar(
        text-link-border-radius,
        cv.getcssvar($name: text-focus-link-border-radius)
      );
      @include cv.cssvar(text-link-box-shadow, cv.getcssvar($name: text-focus-link-box-shadow));
    }
  }

  &:active,
  &.#{config.$framework-prefix}-is-active {
    @include cv.cssvar(text-link-color, cv.getcssvar($name: 'text-active-link-color'));
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &.#{config.$framework-prefix}-is-standalone {
    // prettier-ignore
    @include cv.cssvars(
      (
        text-link-font-weight: cv.getcssvar($name: weight-medium),
        text-link-padding-vertical: 0.375rem,
      ),
      $is-global-scope: false
    );

    align-items: center;
    display: inline-flex;
    padding-bottom: cv.getcssvar($name: 'text-link-padding-vertical');
    padding-top: cv.getcssvar($name: 'text-link-padding-vertical');
    position: relative;
    text-decoration: none;

    &::after {
      background-color: currentColor;
      bottom: calc(#{cv.getcssvar($name: 'text-link-padding-vertical')} - 2px);
      content: '';
      display: none;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
    }

    .#{config.$framework-prefix}-icon {
      height: cv.getcssvar($name: 'text-link-icon-dimensions');
      width: cv.getcssvar($name: 'text-link-icon-dimensions');

      &:first-child {
        margin-right: 0.25rem;
      }

      &:last-child {
        margin-left: 0.25rem;
      }
    }

    &:hover {
      &::after {
        display: block;
      }
    }

    &:active {
      &::after {
        display: none;
      }
    }

    &:focus,
    &.#{config.$framework-prefix}-is-focus {
      &:not(:active) {
        &::after {
          display: none;
        }
      }
    }
  }

  &.#{config.$framework-prefix}-is-small {
    // prettier-ignore
    @include cv.cssvars(
      (
        text-link-font-size: cv.getcssvar($name: 'text-small-link-font-size'),
        text-link-line-height: cv.getcssvar($name: 'text-small-link-line-height'),
        text-link-icon-dimensions: cv.getcssvar($name: 'text-small-link-icon-dimensions'),
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-large {
    // prettier-ignore
    @include cv.cssvars(
      (
        text-link-font-size: cv.getcssvar($name: 'text-large-link-font-size'),
        text-link-line-height: cv.getcssvar($name: 'text-large-link-line-height'),
        text-link-icon-dimensions: cv.getcssvar($name: 'text-large-link-icon-dimensions'),
      ),
      $is-global-scope: false
    );
  }
}

.#{config.$framework-prefix}-text-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -0.5rem;

  .#{config.$framework-prefix}-text-link {
    margin-bottom: 0.5rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
