@use 'sass:map';
@use './variables' as config;
@use '../helpers/css-variables' as cv;

/* Spacing */
$spacing-base: 0.25rem;
$spacing-factors: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 16, 32;
$spacing: ();

@each $factor in $spacing-factors {
  $other: (
    'space-#{$factor}': $factor * $spacing-base,
  );
  $spacing: map.merge($spacing, $other);
}

@include cv.cssvars($spacing);

@each $n in map.keys($spacing) {
  .#{config.$framework-prefix}-#{$n} {
    @include cv.cssvars(
      (
        space: cv.getcssvar($name: $n),
      ),
      $is-global-scope: false
    );
  }
}
