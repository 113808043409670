@font-face {
  font-family: 'Cera Pro';
  src: url('/src/assets/Cera Pro/01_Cera Pro OTF/cerapro-regular.otf');
  src: url('/src/assets/Cera Pro/02_Cera Pro Webfont/Webfonts/cerapro_regular/cerapro-regular-webfont.woff2'),
    format('woff2'),
    url('/src/assets/Cera Pro/02_Cera Pro Webfont/Webfonts/cerapro_regular/cerapro-regular-webfont.woff'),
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/src/assets/Cera Pro/01_Cera Pro OTF/cerapro-medium.otf');
  src: url('/src/assets/Cera Pro/02_Cera Pro Webfont/Webfonts/cerapro_medium/cerapro-medium-webfont.woff2'),
    format('woff2'),
    url('/src/assets/Cera Pro/02_Cera Pro Webfont/Webfonts/cerapro_medium/cerapro-medium-webfont.woff'),
    format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/src/assets/Cera Pro/01_Cera Pro OTF/cerapro-bold.otf');
  src: url('/src/assets/Cera Pro/02_Cera Pro Webfont/Webfonts/cerapro_bold/cerapro-bold-webfont.woff2'),
    format('woff2'),
    url('/src/assets/Cera Pro/02_Cera Pro Webfont/Webfonts/cerapro_bold/cerapro-bold-webfont.woff'),
    format('woff');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Cera Pro', sans-serif;
  line-height: 1.5;
  letter-spacing: 0.1px;
  box-sizing: border-box;
}

strong {
  font-weight: 500;
}

footer a {
  /* TODO: Remove !important when klik library is removed */
  text-decoration: underline !important;
}

a:link {
  color: #277bbe;
}

a:hover {
  color: #174a72;
}
