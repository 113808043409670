@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Numeric Input
/// @group Form
/// @name Numeric Input
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-numeric {
  // prettier-ignore
  @include cv.cssvars(
    (
      numeric-spacing: 0.25rem,
    ),
    $is-global-scope: false
  );

  display: flex;
  font-feature-settings: 'tnum' on, 'lnum' on;

  .#{config.$framework-prefix}-input {
    // prettier-ignore
    @include cv.cssvars(
      (
        input-width: 3em,
      ),
      $is-global-scope: false
    );

    text-align: center;
  }

  .#{config.$framework-prefix}-button {
    &:first-child {
      margin-right: cv.getcssvar($name: numeric-spacing);
    }

    &:last-child {
      margin-left: cv.getcssvar($name: numeric-spacing);
    }
  }
}
