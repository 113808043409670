@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Checkbox and Radio
/// @group Form
/// @name Checkbox and Radio
/// @since 0.0.1
/// @author Jeremy Thomas
%#{config.$framework-prefix}-toggle {
  // prettier-ignore
  @include cv.cssvars(
    (
      color-primary: cv.getcssvar($name: 'primary-color'),
      color-primary-invert: cv.getcssvar($name: 'primary-color-invert'),
      color-secondary: cv.getcssvar($name: 'focus-outer-color'),
      box-dimensions: cv.getcssvar($name: 'size-05'),
      icon-dimensions: 0.75rem,

      shape-background-color: cv.getcolor($name: 'scheme-main'),
      shape-border-color: cv.getcolor($name: 'slate-40'),
      shape-border-style: solid,
      shape-border-width: 1px,

      // Status: error
      shape-error-border-width: 2px,

      // State: hover
      shape-hover-border-width: 2px,

      // State: disabled
      shape-disabled-background-color: cv.getcolor($name: 'slate-05'),
      shape-disabled-border-color: cv.getcolor($name: 'slate-40'),
      shape-disabled-border-width: 1px,

      // Text
      text-margin: 0.5rem,
    ),
    $is-global-scope: false
  );

  display: flex;
  position: relative;

  &.#{config.$framework-prefix}-status-error {
    @include cv.cssvar(color-primary, cv.getcssvar($name: 'status-base'));
    @include cv.cssvar(color-secondary, cv.getcssvar($name: 'status-base'));

    %#{config.$framework-prefix}-toggle-shape {
      @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'status-base'));
      @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-error-border-width'));
    }
  }

  &:not(.#{config.$framework-prefix}-status-error) {
    %#{config.$framework-prefix}-toggle-hidden {
      &[disabled] ~ %#{config.$framework-prefix}-toggle-shape {
        @include cv.cssvar(
          shape-background-color,
          cv.getcssvar($name: 'shape-disabled-background-color')
        );
        @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'shape-disabled-border-color'));
        @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-disabled-border-width'));

        opacity: cv.getcssvar($name: 'disabled-opacity');
      }

      &[disabled] ~ %#{config.$framework-prefix}-toggle-text {
        opacity: cv.getcssvar($name: 'disabled-opacity');
      }
    }
  }

  &.#{config.$framework-prefix}-is-before {
    %#{config.$framework-prefix}-toggle-hidden {
      left: auto;
      right: calc(#{cv.getcssvar($name: 'box-dimensions')} / 2);
      transform: translate(50%, -50%);
    }
  }
}

%#{config.$framework-prefix}-toggle-label {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  vertical-align: top;

  .#{config.$framework-prefix}-icon {
    display: none;
    width: cv.getcssvar($name: 'icon-dimensions');
  }
}

%#{config.$framework-prefix}-toggle-hidden {
  cursor: pointer;
  left: calc(#{cv.getcssvar($name: 'box-dimensions')} / 2);
  opacity: 0;
  position: absolute;
  top: calc(#{cv.getcssvar($name: 'box-dimensions')} / 2);
  transform: translate(-50%, -50%);
  z-index: 1;

  &:hover ~ %#{config.$framework-prefix}-toggle-shape {
    @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-hover-border-width'));
  }

  &:focus:not(:active) ~ %#{config.$framework-prefix}-toggle-shape {
    @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'shape-focus-border-color'));
    @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-focus-border-width'));
  }

  &:active:not([disabled]) ~ %#{config.$framework-prefix}-toggle-shape {
    transform: translateY(1px);
  }

  &:checked ~ %#{config.$framework-prefix}-toggle-shape {
    @include cv.cssvar(
      shape-background-color,
      cv.getcssvar($name: 'shape-checked-background-color')
    );
    @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'shape-checked-border-color'));
    @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-checked-border-width'));
    @include cv.cssvar(shape-color, cv.getcssvar($name: 'shape-checked-color'));
  }

  &:checked:focus:not(:active) ~ .#{config.$framework-prefix}-radio-shape {
    @include cv.cssvar(
      shape-background-color,
      cv.getcssvar($name: 'shape-checked-focus-background-color')
    );
    @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'shape-checked-focus-border-color'));
    @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-checked-focus-border-width'));
  }

  &[disabled] ~ %#{config.$framework-prefix}-toggle-shape {
    @include cv.cssvar(
      shape-background-color,
      cv.getcssvar($name: 'shape-disabled-background-color')
    );
    @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'shape-disabled-border-color'));
    @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-disabled-border-width'));

    opacity: cv.getcssvar($name: 'disabled-opacity');
  }

  &[disabled] ~ %#{config.$framework-prefix}-toggle-text {
    opacity: cv.getcssvar($name: 'disabled-opacity');
  }
}

%#{config.$framework-prefix}-toggle-shape {
  align-items: center;
  background-color: cv.getcssvar($name: 'shape-background-color');
  border-color: cv.getcssvar($name: 'shape-border-color');
  border-radius: cv.getcssvar($name: 'shape-border-radius');
  border-style: cv.getcssvar($name: 'shape-border-style');
  border-width: cv.getcssvar($name: 'shape-border-width');
  color: cv.getcssvar($name: 'shape-color');
  display: inline-flex;
  height: cv.getcssvar($name: 'box-dimensions');
  justify-content: center;
  position: relative;
  vertical-align: top;
  width: cv.getcssvar($name: 'box-dimensions');
}

%#{config.$framework-prefix}-toggle-text {
  line-height: cv.getcssvar($name: 'box-dimensions');

  &:first-child,
  &:nth-child(2) {
    margin-right: cv.getcssvar($name: 'text-margin');
  }

  &:last-child {
    margin-left: cv.getcssvar($name: 'text-margin');
  }
}

// Checkbox
.#{config.$framework-prefix}-checkbox {
  // prettier-ignore
  @include cv.cssvars(
    (
      shape-border-radius: 0.25rem,
      shape-focus-border-color: cv.getcssvar($name: 'focus-outer-color'),
      shape-focus-border-width: cv.getcssvar($name: focus-outer-size),
      shape-checked-background-color: cv.getcssvar($name: 'color-secondary'),
      shape-checked-border-color: cv.getcssvar($name: 'color-secondary'),
      shape-checked-color: cv.getcssvar($name: 'primary-color-invert'),
      shape-checked-border-width: 2px,
      shape-checked-focus-border-color: cv.getcssvar($name: focus-outer-color),
      shape-checked-focus-border-width: 1px,
    ),
    $is-global-scope: false
  );
  @extend %#{config.$framework-prefix}-toggle;
}

.#{config.$framework-prefix}-checkbox-label {
  @extend %#{config.$framework-prefix}-toggle-label;
}

.#{config.$framework-prefix}-checkbox-hidden {
  @extend %#{config.$framework-prefix}-toggle-hidden;

  &:indeterminate ~ %#{config.$framework-prefix}-toggle-shape,
  &:checked ~ %#{config.$framework-prefix}-toggle-shape {
    @include cv.cssvar(
      shape-background-color,
      cv.getcssvar($name: 'shape-checked-background-color')
    );
    @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'shape-checked-border-color'));
    @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'shape-checked-border-width'));
    @include cv.cssvar(shape-color, cv.getcssvar($name: 'shape-checked-color'));

    .#{config.$framework-prefix}-icon {
      display: flex;
    }
  }

  &:checked:focus:not(:active) ~ .#{config.$framework-prefix}-checkbox-shape {
    @include cv.cssvar(shape-border-color, cv.getcssvar($name: 'focus-inner-color'));
    @include cv.cssvar(shape-border-width, cv.getcssvar($name: 'focus-small-inner-size'));

    box-shadow: 0 0 0 cv.getcssvar($name: focus-small-outer-size)
      cv.getcssvar($name: 'focus-outer-color');
  }
}

.#{config.$framework-prefix}-checkbox-shape {
  @extend %#{config.$framework-prefix}-toggle-shape;
}

.#{config.$framework-prefix}-checkbox-text {
  @extend %#{config.$framework-prefix}-toggle-text;
}

// Radio
.#{config.$framework-prefix}-radio {
  // prettier-ignore
  @include cv.cssvars(
    (
      shape-focus-border-color: cv.getcssvar($name: 'focus-outer-color'),
      shape-focus-border-width: 1px,
      shape-checked-background-color: cv.getcssvar($name: 'color-primary'),
      shape-checked-border-color: cv.getcssvar($name: 'color-primary'),
      shape-checked-border-width: 1px,
      shape-checked-focus-background-color: cv.getcssvar($name: 'focus-outer-color'),
      shape-checked-focus-border-color: cv.getcssvar($name: 'focus-inner-color'),
      shape-checked-focus-border-width: 1px,
    ),
    $is-global-scope: false
  );
  @extend %#{config.$framework-prefix}-toggle;
}

.#{config.$framework-prefix}-radio-label {
  @extend %#{config.$framework-prefix}-toggle-label;
}

.#{config.$framework-prefix}-radio-hidden {
  @extend %#{config.$framework-prefix}-toggle-hidden;

  &:focus:not(:active) ~ .#{config.$framework-prefix}-radio-shape {
    box-shadow: 0 0 0 1px cv.getcssvar($name: 'shape-border-color');
  }

  &:checked ~ .#{config.$framework-prefix}-radio-shape {
    box-shadow: inset 0 0 0 2px cv.getcssvar($name: 'color-primary-invert');
  }

  &:checked:focus:not(:active) ~ .#{config.$framework-prefix}-radio-shape {
    box-shadow: inset 0 0 0 2px cv.getcssvar($name: 'focus-inner-color'),
      0 0 0 1px cv.getcssvar($name: 'focus-outer-color');
  }
}

// Radio
.#{config.$framework-prefix}-radio-shape {
  // prettier-ignore
  @include cv.cssvars(
    (
      shape-border-radius: cv.getcssvar($name: 'radius-rounded'),
      shape-inner-shadow: none,
      shape-outer-shadow: none,
    ),
    $is-global-scope: false
  );

  @extend %#{config.$framework-prefix}-toggle-shape;
}

.#{config.$framework-prefix}-radio-text {
  @extend %#{config.$framework-prefix}-toggle-text;
}

.#{config.$framework-prefix}-radios {
  display: flex;
  flex-wrap: wrap;

  .#{config.$framework-prefix}-radio {
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}
