@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

$grid-columns: 12;
$colmn-name: 'col';
$offset-name: 'offset';

$breakpoints: (
  'md': config.$medium-bp,
  'lg': config.$large-bp,
  'xl': config.$extra-large-bp,
);

/// Layout
/// @group Layout
/// @name Layout
/// @since 0.0.1
/// @author Simon Groenborg
.#{config.$framework-prefix}-container {
  @include cv.cssvars(
    (
      default-padding: cv.getcssvar($name: 'space-10'),
    ),
    $is-global-scope: false
  );

  margin: 0 auto;
  padding: 0 cv.getcssvar($name: 'space', $fallback: cv.getcssvar($name: 'default-padding'));
  width: 100%;

  @include mx.from(config.$medium-bp) {
    max-width: config.$medium-bp;
  }
  @include mx.from(config.$large-bp) {
    max-width: config.$large-bp;
  }
  @include mx.from(config.$extra-large-bp) {
    max-width: config.$extra-large-bp;
  }
}

.#{config.$framework-prefix}-areas {
  @include cv.cssvars(
    (
      default-grid-gap: cv.getcssvar($name: 'space-6'),
    ),
    $is-global-scope: false
  );

  display: grid;
  grid-gap: cv.getcssvar($name: 'space', $fallback: cv.getcssvar($name: 'default-grid-gap'));
  padding: 0 cv.getcssvar($name: 'space-10');

  @include mx.from(config.$medium-bp) {
    padding: 0 cv.getcssvar($name: 'space-12');
  }
}

/// TODO: Align with designers the standard spacing between elements
.#{config.$framework-prefix}-row {
  @include cv.cssvars(
    (
      default-row-gap: 1.5rem,
    ),
    $is-global-scope: false
  );

  display: grid;
  grid-gap: cv.getcssvar($name: 'space', $fallback: cv.getcssvar($name: 'default-row-gap'));
  grid-template-columns: repeat($grid-columns, 1fr);

  &:not(:last-child) {
    margin-bottom: cv.getcssvar($name: 'space', $fallback: 1.5rem);
  }
}

@mixin create-col($size) {
  grid-column: span $size;
}

@mixin create-offset($start) {
  grid-column-start: $start;
}

@for $i from 1 through $grid-columns {
  .#{config.$framework-prefix}-#{$colmn-name}-#{$i} {
    @include create-col($i);
  }

  .#{config.$framework-prefix}-#{$offset-name}-#{$i} {
    @include create-offset($i);
  }
}

@each $name, $bp in $breakpoints {
  @for $i from 1 through $grid-columns {
    .#{config.$framework-prefix}-#{$colmn-name}-#{$name}-#{$i} {
      @include mx.from($bp) {
        @include create-col($i);
      }
    }

    .#{config.$framework-prefix}-#{$offset-name}-#{$name}-#{$i} {
      @include create-offset($i);
    }
  }
}
