@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Form Label
/// @group Form
/// @name Form Label
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-form-label {
  // prettier-ignore
  @include cv.cssvars(
    (
      label-margin-bottom: 0.5rem,
    ),
    $is-global-scope: false
  );

  margin-bottom: cv.getcssvar($name: 'label-margin-bottom');

  label[for] {
    cursor: pointer;
  }
}
