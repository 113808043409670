/// Mixin to create a media query from a breakpoint.
/// @group helpers
/// @param {px} $breakpoint - the value of the breakpoint in pixels
///
/// @example scss
///   @include until($medium-breakpoint) {
///      --size: 1.5rem;
///   }
@mixin from($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

/// Mixin to create a media query until a breakpoint.
/// @group helpers
/// @param {px} $breakpoint - the value of the breakpoint in pixels
///
/// @example scss
///   @include until($medium-breakpoint) {
///      --size: 1.5rem;
///   }
@mixin until($breakpoint) {
  @media screen and (max-width: $breakpoint - 1px) {
    @content;
  }
}
