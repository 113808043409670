@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Stack Menu
/// @group Navigation
/// @name Stack Menu
/// @since 0.0.1
/// @author Jeremy Thomas

.#{config.$framework-prefix}-menu-stack {
  // prettier-ignore
  @include cv.cssvars(
    (
      // Base
      stack-background-color: cv.getcssvar($name: 'primary-base'),
      stack-substack-list-padding: 0 1.25rem,

      // List
      stack-list-background-color: rgba(black, 0.1),
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: stack-background-color);

  .#{config.$framework-prefix}-menu-list {
    background-color: cv.getcssvar($name: stack-list-background-color);
  }

  // Nested
  .#{config.$framework-prefix}-menu-stack {
    display: none;

    .#{config.$framework-prefix}-menu-list {
      padding: cv.getcssvar($name: stack-substack-list-padding);
    }
  }

  .#{config.$framework-prefix}-menu-item {
    &.#{config.$framework-prefix}-is-open {
      > .#{config.$framework-prefix}-menu-stack {
        display: block;
      }
    }
  }
}
