@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Drawer
/// @group Navigation
/// @name Drawer
/// @since 0.0.1
/// @author Simon Groenborg
.#{config.$framework-prefix}-drawer {
  @include cv.cssvars(
    (
      drawer-background-color: cv.getcolor($name: 'light-blue-base'),
      drawer-color: cv.getcolor($name: 'white'),
      drawer-padding: 0 1rem,
      drawer-nav-margin-left: 0.5rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: drawer-background-color);
  color: cv.getcssvar($name: drawer-color);
  padding: cv.getcssvar($name: drawer-padding);
}

.#{config.$framework-prefix}-drawer-nav {
  background-color: cv.getcssvar($name: drawer-background-color);
  bottom: 0;
  display: block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  transform: translateX(0);
  transition-duration: 300ms;
  transition-property: transform;
  z-index: 1500;

  &.#{config.$framework-prefix}-is-fullscreen {
    left: 0rem;
    right: 0rem;
    display: none;

    &.#{config.$framework-prefix}-is-open {
      display: block;
    }
  }

  &.#{config.$framework-prefix}-from-left {
    left: -15rem;
    width: 15rem;
    &.#{config.$framework-prefix}-is-open {
      transform: translateX(15rem);
    }
  }

  &.#{config.$framework-prefix}-from-right {
    right: -15rem;
    width: 15rem;
    &.#{config.$framework-prefix}-is-open {
      transform: translateX(-15rem);
    }
  }
}
