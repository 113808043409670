@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Button Toggle
/// @group Elements
/// @name Button Toggle
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-button-toggle {
  // prettier-ignore
  @include cv.cssvars(
    (
      button-toggle-item-background-color: cv.getcssvar('scheme-05'),
      button-toggle-item-border-color: rgba(0, 0, 0, 0.25),
      button-toggle-item-border-radius: 0.25rem,
      button-toggle-item-border-style: solid,
      button-toggle-item-border-width: 1px,
      button-toggle-item-font-size: cv.getcssvar($name: 'size-0'),
      button-toggle-item-font-weight: cv.getcssvar($name: 'weight-regular'),
      button-toggle-item-box-shadow-color: rgba(cv.getcssvar($name: 'shadow-color-rgb'), 0.2),
      button-toggle-item-box-shadow: 0 1px 0 cv.getcssvar($name: 'button-toggle-item-box-shadow-color'),
      button-toggle-item-padding-horizontal: calc(1rem - #{cv.getcssvar($name: 'button-toggle-item-border-width')}),
      button-toggle-item-padding-vertical: 0.75rem,
      button-toggle-item-line-height: 1.5rem,

      // Icon
      button-toggle-icon-font-size: 0.875rem,
      button-toggle-icon-margin: 0.25rem,

      // Icon only
      button-toggle-icon-only-icon-font-size: 1.25rem,
      button-toggle-icon-only-icon-margin: 0,
      button-toggle-icon-only-small-icon-font-size: 0.75rem,
      button-toggle-icon-only-large-icon-font-size: 1.5rem,

      // State: hover
      button-toggle-item-hover-background-color: cv.getcssvar('scheme-20'),

      // State: focus
      button-toggle-item-focus-box-shadow: (
        cv.getcssvar($name: focus-box-shadow)
      ),

      // State: selected
      button-toggle-item-selected-background-color: cv.getcssvar('primary-60'),
      button-toggle-item-selected-box-shadow-color: cv.getcssvar('shadow-color'),
      button-toggle-item-selected-border-color: transparent,
      button-toggle-item-selected-color: cv.getcssvar('primary-color-invert'),
      button-toggle-item-selected-box-shadow: inset 0px 2px 0px cv.getcssvar($name: 'button-toggle-item-box-shadow-color'),
      button-toggle-item-selected-focus-box-shadow: (
        cv.getcssvar($name: focus-box-shadow),
        inset 0px 2px 0px cv.getcssvar($name: 'button-toggle-item-box-shadow-color')
      ),

      // Size: small
      button-toggle-small-item-font-size: cv.getcssvar('size-m1'),
      button-toggle-small-item-line-height: 1rem,
      button-toggle-small-item-padding-horizontal: 0.75rem,
      button-toggle-small-item-padding-vertical: 0.5rem,
      button-toggle-small-item-icon-font-size: 0.625rem,
      button-toggle-small-item-icon-only-icon-font-size: 1rem,

      // Size: large
      button-toggle-large-item-font-size: cv.getcssvar('size-05'),
      button-toggle-large-item-line-height: 1.5rem,
      button-toggle-large-item-padding-horizontal: 1.25rem,
      button-toggle-large-item-padding-vertical: 1.25rem,
      button-toggle-large-item-icon-font-size: 0.875rem,
      button-toggle-large-item-icon-only-icon-font-size: 1.25rem,
    ),
    $is-global-scope: false
  );

  display: flex;

  &.#{config.$framework-prefix}-is-fullwidth {
    .#{config.$framework-prefix}-button-toggle-item {
      flex-grow: 1;
    }
  }

  &.#{config.$framework-prefix}-is-small {
    .#{config.$framework-prefix}-button-toggle-item {
      @include cv.cssvars(
        (
          button-toggle-item-font-size: cv.getcssvar('button-toggle-small-item-font-size'),
          button-toggle-item-line-height: cv.getcssvar('button-toggle-small-item-line-height'),
          button-toggle-item-padding-horizontal:
            cv.getcssvar('button-toggle-small-item-padding-horizontal'),
          button-toggle-item-padding-vertical:
            cv.getcssvar('button-toggle-small-item-padding-vertical'),
        ),
        $is-global-scope: false
      );

      .#{config.$framework-prefix}-icon {
        @include cv.cssvar(
          $name: 'button-toggle-icon-font-size',
          $value: cv.getcssvar(button-toggle-small-item-icon-font-size)
        );
      }

      &.#{config.$framework-prefix}-is-icon-only {
        .#{config.$framework-prefix}-icon {
          @include cv.cssvar(
            $name: 'button-toggle-icon-font-size',
            $value: cv.getcssvar(button-toggle-small-item-icon-only-icon-font-size)
          );
        }
      }
    }
  }

  &.#{config.$framework-prefix}-is-large {
    .#{config.$framework-prefix}-button-toggle-item {
      @include cv.cssvars(
        (
          button-toggle-item-font-size: cv.getcssvar('button-toggle-large-item-font-size'),
          button-toggle-item-line-height: cv.getcssvar('button-toggle-large-item-line-height'),
          button-toggle-item-padding-horizontal:
            cv.getcssvar('button-toggle-large-item-padding-horizontal'),
          button-toggle-item-padding-vertical:
            cv.getcssvar('button-toggle-large-item-padding-vertical'),
        ),
        $is-global-scope: false
      );

      .#{config.$framework-prefix}-icon {
        @include cv.cssvar(
          $name: 'button-toggle-icon-font-size',
          $value: cv.getcssvar(button-toggle-large-item-icon-font-size)
        );
      }

      &.#{config.$framework-prefix}-is-icon-only {
        .#{config.$framework-prefix}-icon {
          @include cv.cssvar(
            $name: 'button-toggle-icon-font-size',
            $value: cv.getcssvar(button-toggle-large-item-icon-only-icon-font-size)
          );
        }
      }
    }
  }
}

.#{config.$framework-prefix}-button-toggle-item {
  @extend %reset;
  @extend %unselectable;

  align-items: center;
  background-color: cv.getcssvar($name: 'button-toggle-item-background-color');
  border-bottom-style: cv.getcssvar($name: 'button-toggle-item-border-style');
  border-bottom-width: cv.getcssvar($name: 'button-toggle-item-border-width');
  border-color: cv.getcssvar($name: 'button-toggle-item-border-color');
  box-shadow: cv.getcssvar($name: 'button-toggle-item-box-shadow');
  color: cv.getcssvar($name: 'button-toggle-item-color');
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: cv.getcssvar($name: 'button-toggle-item-font-size');
  font-weight: cv.getcssvar($name: 'button-toggle-item-font-weight');
  justify-content: center;
  line-height: cv.getcssvar($name: 'button-toggle-item-line-height');
  padding-bottom: calc(
    #{cv.getcssvar($name: 'button-toggle-item-padding-vertical')} - #{cv.getcssvar(
        $name: 'button-toggle-item-border-width'
      )}
  );
  padding-left: cv.getcssvar($name: 'button-toggle-item-padding-horizontal');
  padding-right: cv.getcssvar($name: 'button-toggle-item-padding-horizontal');
  padding-top: cv.getcssvar($name: 'button-toggle-item-padding-vertical');
  position: relative;
  text-align: center;
  white-space: nowrap;

  &:first-child {
    border-bottom-left-radius: cv.getcssvar($name: 'button-toggle-item-border-radius');
    border-top-left-radius: cv.getcssvar($name: 'button-toggle-item-border-radius');
  }

  &:last-child {
    border-bottom-right-radius: cv.getcssvar($name: 'button-toggle-item-border-radius');
    border-top-right-radius: cv.getcssvar($name: 'button-toggle-item-border-radius');
  }

  &:not(:last-child) {
    border-right-style: cv.getcssvar($name: 'button-toggle-item-border-style');
    border-right-width: cv.getcssvar($name: 'button-toggle-item-border-width');
  }

  &:hover,
  &.#{config.$framework-prefix}-is-hover {
    @include cv.cssvar(
      $name: 'button-toggle-item-background-color',
      $value: cv.getcssvar(button-toggle-item-hover-background-color)
    );

    z-index: 1;
  }

  &:active,
  &.#{config.$framework-prefix}-is-active {
    box-shadow: none;
    transform: translateY(1px);
    z-index: 1;
  }

  &:focus,
  &.#{config.$framework-prefix}-is-focus {
    &:not(:active) {
      @include cv.cssvar(
        $name: 'button-toggle-item-box-shadow',
        $value: cv.getcssvar(button-toggle-item-focus-box-shadow)
      );

      z-index: 2;
    }
  }

  &[disabled],
  &.#{config.$framework-prefix}-is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.#{config.$framework-prefix}-is-selected {
    @include cv.cssvars(
      (
        button-toggle-item-background-color:
          cv.getcssvar('button-toggle-item-selected-background-color'),
        button-toggle-item-box-shadow-color:
          cv.getcssvar('button-toggle-item-selected-box-shadow-color'),
        button-toggle-item-box-shadow: cv.getcssvar('button-toggle-item-selected-box-shadow'),
        button-toggle-item-border-color: cv.getcssvar('button-toggle-item-selected-border-color'),
        button-toggle-item-color: cv.getcssvar('button-toggle-item-selected-color'),
      ),
      $is-global-scope: false
    );

    z-index: 3;

    &:focus {
      // prettier-ignore
      @include cv.cssvar(
        $name: 'button-toggle-item-box-shadow',
        $value: cv.getcssvar('button-toggle-item-selected-focus-box-shadow')
      );
    }
  }

  .#{config.$framework-prefix}-icon {
    font-size: cv.getcssvar($name: 'button-toggle-icon-font-size');

    &:first-child:not(:last-child) {
      margin-right: cv.getcssvar($name: 'button-toggle-icon-margin');
    }

    &:last-child:not(:first-child) {
      margin-left: cv.getcssvar($name: 'button-toggle-icon-margin');
    }
  }

  &.#{config.$framework-prefix}-is-icon-only {
    @include cv.cssvars(
      (
        button-toggle-item-padding-horizontal: 0,
      ),
      $is-global-scope: false
    );

    width: calc(
      #{cv.getcssvar('button-toggle-item-line-height')} + 2 * #{cv.getcssvar(
          'button-toggle-item-padding-vertical'
        )}
    );

    .#{config.$framework-prefix}-icon {
      @include cv.cssvars(
        (
          button-toggle-icon-font-size: cv.getcssvar('button-toggle-icon-only-icon-font-size'),
          button-toggle-icon-margin: cv.getcssvar('button-toggle-icon-only-icon-margin'),
        ),
        $is-global-scope: false
      );
    }
  }

  &.#{config.$framework-prefix}-is-icon-small {
    .#{config.$framework-prefix}-icon {
      @include cv.cssvars(
        (
          button-toggle-icon-font-size: cv.getcssvar('button-toggle-icon-only-small-icon-font-size'),
        ),
        $is-global-scope: false
      );
    }
  }

  &.#{config.$framework-prefix}-is-icon-large {
    .#{config.$framework-prefix}-icon {
      @include cv.cssvars(
        (
          button-toggle-icon-font-size: cv.getcssvar('button-toggle-icon-only-large-icon-font-size'),
        ),
        $is-global-scope: false
      );
    }
  }
}
