@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Help
/// @group Form
/// @name Help
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-help {
  // prettier-ignore
  @include cv.cssvars(
    (
      help-color: cv.getcssvar($name: 'scheme-base'),
      help-font-size: cv.getcolor($name: 'size-m1'),
      help-letter-spacing: -0.03em,
      help-margin-top: 0.5rem,
    ),
    $is-global-scope: false
  );

  color: cv.getcssvar($name: 'help-color');
  font-size: cv.getcssvar($name: 'help-font-size');
  letter-spacing: cv.getcssvar($name: 'help-letter-spacing');
  margin-top: cv.getcssvar($name: 'help-margin-top');
}
