@use 'sass:meta';
@use '../../config/variables';

/// Use this mixin to declare a set of CSS Custom Properties or CSS Variables.
/// The variables in $css-variables will be properly prefixed with the framework prefix.
/// The use of this mixin is encouraged over defining CSS Variables yourself
/// TODO: Read the guidelines when to use globally and/or locally scoped CSS Variables
///
/// @group helpers
/// @param {map} $css-variables - the map of css variables you want to add a prefix to
/// @param {boolean} $is-global-scope [true] - whether to include this at the :root or local scope
/// @param {string} $prefix [klik] - the prefix of your custom properties
///
/// @example scss - Example only using $css-variables
///   @include cssvars((
///    base-font-size: 65.5%,
///    font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif},
///    primary-color: #33b5e5,
///    secondary-color: #ff500a,
///   ));
///
///   // Will result in
///
///   :root {
///     --klik-base-font-size: 65.5%;
///     --klik-font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
///     --klik-primary-color: #33b5e5;
///     --klik-secondary-color: #ff500a;
///   }
///
/// @example scss - Example defining locally scoped $css-variables
///   .header {
///     @include cssvars((
///       base-font-size: 65.5%,
///       font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif},
///       primary-color: #33b5e5,
///     secondary-color: #ff500a,
///     ), $is-global-scope: false);
///   }
///
///   // Will result in
///
///   .header {
///     --klik-base-font-size: 65.5%;
///     --klik-font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
///     --klik-primary-color: #33b5e5;
///     --klik-secondary-color: #ff500a;
///   }
///
@mixin cssvars(
  $css-variables,
  $is-global-scope: true,
  $prefix: '',
  $framework-prefix: variables.$framework-prefix
) {
  @if $is-global-scope {
    @at-root :root {
      @each $name, $value in $css-variables {
        // TODO: There are some values that might, unlike strings or quoted strings, end up here
        // being quoted, we need to take a look at how to fix that

        // stylelint-disable-next-line custom-property-pattern
        --#{$framework-prefix}-#{$prefix}#{$name}: #{meta.inspect($value: $value)};
      }
    }
  } @else {
    @each $name, $value in $css-variables {
      // TODO: There are some values that might, unlike strings or quoted strings, end up here
      // being quoted, we need to take a look at how to fix that

      // stylelint-disable-next-line custom-property-pattern
      --#{$framework-prefix}-#{$prefix}#{$name}: #{meta.inspect($value: $value)};
    }
  }
}

/// Mixin that updates (overrides) a css variable's value.
/// @group helpers
/// @param {string} $name - the name of the custom property
/// @param {string} $prefix [klik] - the prefix of your css variables
///
/// @example scss
///   @include cssvars((
///    base-font-size: 65.5%,
///    font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif},
///    primary-color: #33b5e5,
///    secondary-color: #ff500a,
///   ));
@mixin cssvar($name, $value: '', $prefix: variables.$framework-prefix) {
  // TODO: There are some values that might, unlike strings or quoted strings, end up here
  // being quoted, we need to take a look at how to fix that
  /* stylelint-disable-next-line custom-property-pattern */
  --#{$prefix}-#{$name}: #{meta.inspect($value: $value)};
}
