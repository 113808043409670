@use '../../config/variables';
@use '../css-variables' as cv;

@mixin touch-callout() {
  @supports (-webkit-touch-callout: none) {
    -webkit-touch-callout: none;
  }
}

@mixin user-select($user-select) {
  @include touch-callout();

  -webkit-user-select: $user-select;
  -moz-user-select: $user-select;
  -o-user-select: $user-select;
  -ms-user-select: $user-select;
  user-select: $user-select;
}

%unselectable {
  @include user-select(none);
}

.#{variables.$framework-prefix}-is-unselectable {
  @extend %unselectable;
}

/// Mixin to remove an element's default styling.
/// @group helpers
@mixin reset {
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  font-size: 1em;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: inherit;
  text-decoration: none;
}

%reset {
  @include reset;
}

/// Mixin to remove an element's default styling.
/// @group helpers
@mixin focusable {
  &:focus {
    box-shadow: cv.getcssvar($name: focus-box-shadow);
  }
}

%focusable {
  @include focusable;
}
