@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Typography Label
/// @group Typography
/// @name Typography Label
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-label {
  // prettier-ignore
  @include cv.cssvars(
    (
      label-font-size: cv.getcssvar($name: 'size-0'),
      dot-background-color: cv.getcssvar($name: 'status-base'),
      dot-dimensions: 1rem,
      dot-margin: 0.5em,

      // Size: small
      label-small-font-size: cv.getcssvar($name: 'size-m1'),
      dot-small-dimensions: 0.75rem,
    ),
    $is-global-scope: false
  );

  align-items: center;
  display: inline-flex;
  font-size: cv.getcssvar($name: 'label-font-size');
  vertical-align: top;

  &::before {
    background-color: cv.getcssvar($name: 'dot-background-color');
    border-radius: cv.getcssvar($name: 'radius-rounded');
    content: '';
    display: block;
    height: cv.getcssvar($name: 'dot-dimensions');
    margin-right: cv.getcssvar($name: 'dot-margin');
    width: cv.getcssvar($name: 'dot-dimensions');
  }

  &.#{config.$framework-prefix}-is-small {
    @include cv.cssvars(
      (
        label-font-size: cv.getcssvar($name: 'label-small-font-size'),
        dot-dimensions: cv.getcssvar($name: 'dot-small-dimensions'),
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-after {
    &::before {
      margin-left: cv.getcssvar($name: 'dot-margin');
      margin-right: 0;
      order: 2;
    }
  }
}
