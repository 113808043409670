/* eslint-disable */
@use 'sass:map';
@use '../config' as config;
@use '../helpers/css-variables' as cv;

// prettier-ignore
@include cv.cssvars(
  (
    // Scheme color
    scheme-main: cv.getcolor($name: 'white'),
    scheme-02: cv.getcolor($name: 'slate-02'),
    scheme-05: cv.getcolor($name: 'slate-05'),
    scheme-10: cv.getcolor($name: 'slate-10'),
    scheme-20: cv.getcolor($name: 'slate-20'),
    scheme-30: cv.getcolor($name: 'slate-30'),
    scheme-40: cv.getcolor($name: 'slate-40'),
    scheme-50: cv.getcolor($name: 'slate-50'),
    scheme-60: cv.getcolor($name: 'slate-60'),
    scheme-base: cv.getcolor($name: 'slate-base'),
    scheme-80: cv.getcolor($name: 'slate-80'),
    scheme-90: cv.getcolor($name: 'slate-90'),
    scheme-100: cv.getcolor($name: 'slate-100'),

    // RGBA versions
    scheme-rgb: cv.getRGB(map.get(
        $map: config.$neutral-colors,
        $key: 'slate-100',
      )),

    scheme-100-rgb: cv.getRGB(map.get(
        $map: config.$neutral-colors,
        $key: 'slate-100',
      )),

    scheme-rgba05: rgba(cv.getcssvar($name: 'scheme-rgb'), 0.05),
    scheme-rgba10: rgba(cv.getcssvar($name: 'scheme-rgb'), 0.1),
    scheme-rgba80: rgba(cv.getcssvar($name: 'scheme-rgb'), 0.8),
    scheme-rgba40: rgba(cv.getcssvar($name: 'scheme-rgb'), 0.4),

    scheme-100-rgba20: rgba(cv.getcssvar($name: 'scheme-100-rgb'), 0.2),

    // Primary color
    primary-color: cv.getcolor($name: 'light-blue-base'),
    primary-color-invert: cv.getcolor($name: 'white'),
    primary-alt-color: cv.getcolor($name: 'light-blue-90'),
    primary-alt-color-invert: cv.getcolor($name: 'white'),
    primary-02: cv.getcolor($name: 'light-blue-02'),
    primary-05: cv.getcolor($name: 'light-blue-05'),
    primary-10: cv.getcolor($name: 'light-blue-10'),
    primary-20: cv.getcolor($name: 'light-blue-20'),
    primary-30: cv.getcolor($name: 'light-blue-30'),
    primary-40: cv.getcolor($name: 'light-blue-40'),
    primary-50: cv.getcolor($name: 'light-blue-50'),
    primary-60: cv.getcolor($name: 'light-blue-60'),
    primary-base: cv.getcolor($name: 'light-blue-base'),
    primary-80: cv.getcolor($name: 'light-blue-80'),
    primary-90: cv.getcolor($name: 'light-blue-90'),
    primary-100: cv.getcolor($name: 'light-blue-100'),

    // Base colors
    text: cv.getcolor($name: 'slate-100'),
    text-light: cv.getcolor($name: 'slate-base'),

    background: cv.getcssvar($name: 'scheme-rgba10'),
    border: cv.getcolor($name: 'slate-40'),
    border-hover: cv.getcolor($name: 'slate-60'),
    border-radius: 0.25rem,
    border-bis: cv.getcolor($name: 'slate-30'),
    border-ter: cv.getcolor($name: 'slate-20'),
    shadow-color-rgb: (1, 4, 16),
    shadow-color: rgba(cv.getcssvar($name: 'shadow-color-rgb'), 0.1),

    // CTA color (for buttons mainly)
    cta-color: cv.getcolor($name: 'light-blue-base'),
    cta-color-invert: cv.getcolor($name: 'white'),
    cta-alt-color: cv.getcolor($name: 'light-blue-80'),
    cta-alt-color-invert: cv.getcolor($name: 'white'),

    // Typography
    base-font-family: cv.getcssvar($name: 'font-family'),
    base-font-size: cv.getcssvar($name: 'root-size'),
    base-line-height: cv.getcssvar($name: 'line-height-m'),

    // Sizes
    size-default: cv.getcssvar('size-0'),
    line-height-default:
      cv.getcssvar(
        $name: 'line-height-m',
      ),

    // Weight
    weight-default: cv.getcssvar('weight-regular'),
    weight-strong: cv.getcssvar('weight-medium'),

    // Misc
    radius-rounded: 9999px,

    // States
    focus-inner-color: cv.getcolor($name: 'white'),
    focus-inner-size: 2px,
    focus-inner-box-shadow: inset 0 0 0 cv.getcssvar($name: focus-inner-size) cv.getcssvar($name: focus-inner-color),
    focus-outer-color: cv.getcolor($name: 'utility-information-base'),
    focus-outer-size: 3px,
    focus-outer-box-shadow: 0 0 0 cv.getcssvar($name: focus-outer-size) cv.getcssvar($name: focus-outer-color),
    focus-small-inner-size: 1px,
    focus-small-inner-box-shadow: inset 0 0 0 cv.getcssvar($name: focus-small-inner-size) cv.getcssvar($name: focus-inner-color),
    focus-small-outer-size: 2px,
    focus-small-outer-box-shadow: 0 0 0 cv.getcssvar($name: focus-small-outer-size) cv.getcssvar($name: focus-outer-color),
    focus-box-shadow: (
      cv.getcssvar($name: focus-inner-box-shadow),
      cv.getcssvar($name: focus-outer-box-shadow)
    ),
    focus-small-box-shadow: (
      cv.getcssvar($name: focus-small-inner-box-shadow),
      cv.getcssvar($name: focus-small-outer-box-shadow)
    ),

    disabled-background-color: cv.getcolor($name: 'slate-05'),
    disabled-border-color: cv.getcolor($name: 'slate-40'),
    disabled-color: cv.getcolor($name: 'slate-base'),
    disabled-opacity: 0.5
  )
);

// Status colors
@each $name in map.keys(config.$utility-colors) {
  @include cv.cssvars(
    (
      status-#{$name}-base: cv.getcolor($name: 'utility-#{$name}-base'),
      status-#{$name}-alt: cv.getcolor($name: 'utility-#{$name}-light'),
      status-#{$name}-ter: cv.getcolor($name: 'utility-#{$name}-dark')
    )
  );
}
