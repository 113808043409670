@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Popover
/// @group Dialogs
/// @name Popover
/// @since 0.0.1
/// @author Jeremy Thomas
%#{config.$framework-prefix}-arrow-box {
  height: 24px;
  overflow: hidden;
  position: absolute;
  width: 24px;
}

%#{config.$framework-prefix}-arrow-shape {
  border-radius: 0 0 0 cv.getcssvar($name: arrow-border-radius);
  content: '';
  display: block;
  height: cv.getcssvar($name: 'arrow-dimensions');
  position: absolute;
  width: cv.getcssvar($name: 'arrow-dimensions');
}

.#{config.$framework-prefix}-arrow-above,
.#{config.$framework-prefix}-arrow-below {
  @extend %#{config.$framework-prefix}-arrow-box;
}

.#{config.$framework-prefix}-arrow-above::after,
.#{config.$framework-prefix}-arrow-below::after {
  @extend %#{config.$framework-prefix}-arrow-shape;
}

.#{config.$framework-prefix}-arrow-below::after {
  background-color: cv.getcssvar($name: toast-box-shadow-color);
}

.#{config.$framework-prefix}-arrow-above::after {
  background-color: cv.getcssvar($name: 'toast-background-color');
  box-shadow: cv.getcssvar($name: 'arrow-inner-shadow');
}

.#{config.$framework-prefix}-popover {
  // prettier-ignore
  @include cv.cssvars(
    (
      toast-heading-font-size: 1em,
      popover-max-width: 15rem,
      arrow-border-radius: 0.25rem,
      arrow-dimensions: 1rem,
      arrow-edge-offset: 1rem,
      arrow-center-offset: calc(-1 * #{cv.getcssvar($name: 'arrow-dimensions')} / 2 + 1px),
      arrow-outer-shadow-color: cv.getcssvar($name: toast-box-shadow-color),
      arrow-outer-shadow-position: -1px 1px 0,
      arrow-outer-shadow: (
        cv.getcssvar($name: arrow-outer-shadow-position)
        cv.getcssvar($name: arrow-outer-shadow-color)
      ),
      arrow-inner-shadow: inset 0 0 0 1px cv.getcssvar($name: toast-border-color),
    ),
    $is-global-scope: false
  );

  max-width: cv.getcssvar($name: 'popover-max-width');

  .#{config.$framework-prefix}-toast-heading {
    @include cv.cssvars(
      (
        toast-heading-font-size: 1em,
      ),
      $is-global-scope: false
    );
  }

  .#{config.$framework-prefix}-toast-body {
    @include cv.cssvars(
      (
        toast-body-margin-top: 0.25em,
      ),
      $is-global-scope: false
    );
  }

  // North
  &.#{config.$framework-prefix}-arrow-ne,
  &.#{config.$framework-prefix}-arrow-nn,
  &.#{config.$framework-prefix}-arrow-nw {
    %#{config.$framework-prefix}-arrow-box {
      height: 10px;
    }

    .#{config.$framework-prefix}-arrow-above {
      bottom: calc(100% - 1px);
    }

    .#{config.$framework-prefix}-arrow-below {
      bottom: 100%;
      display: none;
    }

    %#{config.$framework-prefix}-arrow-shape {
      left: 2px;
      top: 2px;
      transform: rotate(135deg);
    }
  }

  &.#{config.$framework-prefix}-arrow-ne {
    %#{config.$framework-prefix}-arrow-box {
      left: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }

  &.#{config.$framework-prefix}-arrow-nn {
    %#{config.$framework-prefix}-arrow-box {
      left: calc(50% - 12px);
    }
  }

  &.#{config.$framework-prefix}-arrow-nw {
    %#{config.$framework-prefix}-arrow-box {
      right: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }

  // South
  &.#{config.$framework-prefix}-arrow-se,
  &.#{config.$framework-prefix}-arrow-ss,
  &.#{config.$framework-prefix}-arrow-sw {
    %#{config.$framework-prefix}-arrow-box {
      height: 10px;
    }

    .#{config.$framework-prefix}-arrow-above {
      top: calc(100% - 1px);
    }

    .#{config.$framework-prefix}-arrow-below {
      top: calc(100% + 1px);
    }

    %#{config.$framework-prefix}-arrow-shape {
      bottom: 2px;
      left: 2px;
      transform: rotate(-45deg);
    }
  }

  &.#{config.$framework-prefix}-arrow-se {
    %#{config.$framework-prefix}-arrow-box {
      left: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }

  &.#{config.$framework-prefix}-arrow-ss {
    %#{config.$framework-prefix}-arrow-box {
      left: calc(50% - 12px);
    }
  }

  &.#{config.$framework-prefix}-arrow-sw {
    %#{config.$framework-prefix}-arrow-box {
      right: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }

  // East
  &.#{config.$framework-prefix}-arrow-en,
  &.#{config.$framework-prefix}-arrow-ee,
  &.#{config.$framework-prefix}-arrow-es {
    %#{config.$framework-prefix}-arrow-box {
      width: 10px;
    }

    .#{config.$framework-prefix}-arrow-above {
      left: calc(100% - 1px);
    }

    .#{config.$framework-prefix}-arrow-below {
      left: calc(100%);
    }

    %#{config.$framework-prefix}-arrow-shape {
      right: 2px;
      top: 2px;
      transform: rotate(-135deg);
    }

    .#{config.$framework-prefix}-arrow-below::after {
      right: 3px;
      top: 4px;
    }
  }

  &.#{config.$framework-prefix}-arrow-en {
    %#{config.$framework-prefix}-arrow-box {
      top: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }

  &.#{config.$framework-prefix}-arrow-ee {
    %#{config.$framework-prefix}-arrow-box {
      top: calc(50% - 12px);
    }
  }

  &.#{config.$framework-prefix}-arrow-es {
    %#{config.$framework-prefix}-arrow-box {
      bottom: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }

  // West
  &.#{config.$framework-prefix}-arrow-wn,
  &.#{config.$framework-prefix}-arrow-ww,
  &.#{config.$framework-prefix}-arrow-ws {
    %#{config.$framework-prefix}-arrow-box {
      width: 10px;
    }

    .#{config.$framework-prefix}-arrow-above {
      right: calc(100% - 1px);
    }

    .#{config.$framework-prefix}-arrow-below {
      right: calc(100%);
    }

    %#{config.$framework-prefix}-arrow-shape {
      left: 2px;
      top: 2px;
      transform: rotate(45deg);
    }

    .#{config.$framework-prefix}-arrow-below::after {
      left: 3px;
      top: 4px;
    }
  }

  &.#{config.$framework-prefix}-arrow-wn {
    %#{config.$framework-prefix}-arrow-box {
      top: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }

  &.#{config.$framework-prefix}-arrow-ww {
    %#{config.$framework-prefix}-arrow-box {
      top: calc(50% - 12px);
    }
  }

  &.#{config.$framework-prefix}-arrow-ws {
    %#{config.$framework-prefix}-arrow-box {
      bottom: cv.getcssvar($name: 'arrow-edge-offset');
    }
  }
}

.#{config.$framework-prefix}-tooltip {
  // prettier-ignore
  @include cv.cssvars(
    (
      toast-background-color: cv.getcssvar($name: 'scheme-90'),
      toast-color: cv.getcssvar($name: 'scheme-main'),
      toast-heading-color: cv.getcssvar($name: 'scheme-main'),
      toast-border-color: transparent,
      toast-border-radius: 0.25rem,
      toast-padding: 0.75rem 1rem,
      toast-content-padding-right: 0,
    ),
    $is-global-scope: false
  );
}
