@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

$size4-key: 'size-4';
$size5-key: 'size-5';
$size6-key: 'size-6';

$has-size4: map.has-key(
  $map: config.$typography-sizes,
  $key: $size4-key,
);
$has-size5: map.has-key(
  $map: config.$typography-sizes,
  $key: $size5-key,
);
$has-size6: map.has-key(
  $map: config.$typography-sizes,
  $key: $size6-key,
);

@if $has-size4 and $has-size5 {
  @include mx.until(config.$medium-bp) {
    .#{config.$framework-prefix}-h1 {
      font-size: cv.getcssvar($name: $size4-key);
    }
  }

  @include mx.from(config.$medium-bp) {
    .#{config.$framework-prefix}-h1 {
      font-size: cv.getcssvar($name: $size5-key);
    }
  }
}

@if $has-size5 and $has-size6 {
  @include mx.until(config.$medium-bp) {
    .#{config.$framework-prefix}-display-heading {
      font-size: cv.getcssvar($name: $size5-key);
    }
  }

  @include mx.from(config.$medium-bp) {
    .#{config.$framework-prefix}-display-heading {
      font-size: cv.getcssvar($name: $size6-key);
    }
  }
}
