@use '../../config/variables';

@mixin includeTheme($name, $global-scope: false) {
  @if $global-scope {
    @at-root :root {
      @content;
    }
  } @else {
    :root[data-theme='#{variables.$framework-prefix}-#{$name}'],
    .#{variables.$framework-prefix}-theme-#{$name} {
      @content;
    }
  }
}
