@use '../../config';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  // fraction unit
  --klik-col: 1fr;
  --klik-cols: 12;
  --klik-pad: 5px;

  @media (min-width: 1200px) {
    --klik-pad: 1fr;
    --klik-col: 60px;
  }

  @media (min-width: 1600px) {
    --klik-col: 100px;
  }
}

.grid {
  margin: 40px auto;
  max-width: 1200px;
  padding: 0 20px;

  @media (min-width: 600px) {
    display: flex;

    @supports (display: grid) {
      display: grid;
      grid-gap: 20px;
      grid-template-columns:
        [outer-start] var(--klik-pad) [wrapper-start] repeat(var(--klik-cols), var(--klik-col)) [wrapper-end] var(
          --klik-pad
        )
        [outer-end];
      grid-template-rows: minmax(auto, 300px);
      max-width: none;
      padding: unset;
    }
  }
}

.grid__item {
  min-height: 200px;
  padding: 20px;
  width: 100%;
}

.grid__item--heading {
  background-color: var(--klik-color-main70);
  color: #fefefe;
  grid-column: wrapper-start / wrapper-end;
}

.grid__item--body {
  background-color: var(--klik-color-main50);
  grid-column: wrapper-start / span 6;
}

.grid__item--body-right {
  background-color: var(--klik-color-main30);
  grid-column: span 6 / wrapper-end;
}

.grid__item--fig {
  background-color: var(--klik-color-main10);
  grid-column: span 8 / wrapper-end;
  height: 400px;
}

.grid__item--fig-to-edge {
  background-color: var(--klik-color-main10);
  grid-column: outer-start / 10;
  height: 400px;
}

.grid__item--fw {
  background-color: var(--klik-color-main90);
  color: #efefef;
  grid-column: wrapper-start / wrapper-end;
}
