@use '../../config';
@use '../../helpers/css-variables';
@use '../../helpers/mixins' as mx;

.#{config.$framework-prefix}-icon {
  @extend %reset;
  @extend %unselectable;

  align-items: center;
  color: currentColor;
  display: inline-flex;
  font-size: 1em;
  height: 1em;
  justify-content: center;
  vertical-align: top;
  width: 1em;

  svg {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }

  path {
    fill: currentColor;
  }
}

a.#{config.$framework-prefix}-icon,
button.#{config.$framework-prefix}-icon {
  cursor: pointer;
}
