@use 'sass:map';
@use './variables';
@use '../helpers/css-variables';

/* Colours */
$neutral-colors: (
  'white': #ffffff,
  'black': #141414,
  slate-02: #fcfcfc,
  slate-05: #f8f8f8,
  slate-10: #f1f1f1,
  slate-20: #e4e4e3,
  slate-30: #d6d6d5,
  slate-40: #c8c8c7,
  slate-50: #adadac,
  slate-60: #919190,
  slate-base: #767674,
  slate-80: #5e5e5d,
  slate-90: #474746,
  slate-100: #2f2f2e,
);
@include css-variables.cssvars($neutral-colors, $prefix: '#{variables.$color-prefix}-');

$bold-colors: (
  teal: (
    '02': #fbfcfc,
    '05': #f5f6f7,
    '10': #eaeeef,
    '20': #d6dcde,
    '30': #c1cace,
    '40': #adb9be,
    '50': #84979d,
    '60': #5b747d,
    base: #32525d,
    '80': #29414b,
    '90': #20323a,
    '100': #172229,
  ),
  turquoise: (
    '02': #fafcfd,
    '05': #f2f9fa,
    '10': #e5f2f4,
    '20': #cce6ea,
    '30': #b2d9df,
    '40': #99cdd5,
    '50': #66b4bf,
    '60': #339baa,
    base: #008295,
    '80': #006877,
    '90': #004e59,
    '100': #00343c,
  ),
  light-blue: (
    '02': #fbfcfe,
    '05': #f4f8fc,
    '10': #e9f2f8,
    '20': #d4e5f2,
    '30': #bed7eb,
    '40': #a9cae5,
    '50': #7db0d8,
    '60': #5295cb,
    base: #277bbe,
    '80': #1f6298,
    '90': #174a72,
    '100': #10314c,
  ),
  dark-blue: (
    '02': #fafbfd,
    '05': #f2f6fa,
    '10': #e6edf6,
    '20': #ccdaec,
    '30': #b2c7e3,
    '40': #99b5da,
    '50': #6791c8,
    '60': #346cb6,
    base: #0248a4,
    '80': #013a87,
    '90': #012c6a,
    '100': #001e4d,
  ),
  purple: (
    '02': #fcfbfd,
    '05': #f7f5f9,
    '10': #efecf4,
    '20': #dfd8e8,
    '30': #cfc5dd,
    '40': #bfb1d2,
    '50': #9f8bbc,
    '60': #7f64a6,
    base: #5f3e90,
    '80': #4d3278,
    '90': #3b2660,
    '100': #2a1a47,
  ),
  magenta: (
    '02': #fdfafc,
    '05': #fbf2f7,
    '10': #f7e6ef,
    '20': #efccde,
    '30': #e7b2ce,
    '40': #e099be,
    '50': #d0669d,
    '60': #c1337d,
    base: #b2015d,
    '80': #92004b,
    '90': #73003a,
    '100': #530029,
  ),
  // TODO: add a default 'color-main' scheme ?,
);
@each $name, $values in $bold-colors {
  @include css-variables.cssvars($values, $prefix: '#{variables.$color-prefix}-#{$name}-');
}

/* Colours - Accents */
$accent-colors: (
  gold: (
    light: #fdc84d,
    base: #fdb100,
    dark: #fd7f00,
  ),
  aqua-blue: (
    light: #66e2f6,
    base: #00cff1,
    dark: #00aed4,
  ),
);
@each $name, $values in $accent-colors {
  @include css-variables.cssvars($values, $prefix: '#{variables.$color-prefix}-accent-#{$name}-');
}

/* Colours - Utility */
$utility-colors: (
  information: (
    light: #bfe6f5,
    base: #009bd7,
    dark: #008ccb,
  ),
  success: (
    light: #bde3b9,
    base: #22a317,
    dark: #138b09,
  ),
  warning: (
    light: #ffefb3,
    base: #ffc900,
    dark: #fab400,
  ),
  error: (
    light: #f7b3b6,
    base: #e3000b,
    dark: #c60206,
  ),
);
@each $name, $values in $utility-colors {
  @include css-variables.cssvars($values, $prefix: '#{variables.$color-prefix}-utility-#{$name}-');
}

/* Colours - Shadows */
$shadows: (
  shadow-regular:
    rgba(
      map.get(
        $map: $neutral-colors,
        $key: 'slate-100',
      ),
      0.1
    ),
);
@include css-variables.cssvars($shadows);
