@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Stepper
/// @group Navigation
/// @name Stepper
/// @since 0.0.1
/// @author Simon Groenborg
.#{config.$framework-prefix}-stepper {
  // prettier-ignore
  @include cv.cssvars(
    (
      stepper-color: cv.getcssvar($name: scheme-base),

      // Item
      stepper-item-color: cv.getcssvar($name: scheme-20),
      stepper-item-spacing: 0.5rem,
      stepper-item-width: 8.75rem,

      // Link
      stepper-link-dimensions: 2rem,
      stepper-link-font-size: cv.getcssvar($name: 'size-1'),
      stepper-link-font-weight: cv.getcssvar($name: 'weight-regular'),
      stepper-link-border-radius: cv.getcssvar($name: 'radius-rounded'),
      stepper-link-border-color: cv.getcssvar($name: scheme-20),
      stepper-link-border-style: solid,
      stepper-link-border-width: 3px,
      stepper-link-color: cv.getcssvar($name: scheme-base),

      // Line
      stepper-line-background-color: cv.getcssvar($name: scheme-20),
      stepper-line-radius: cv.getcssvar($name: 'radius-rounded'),
      stepper-line-height: 0.25rem,
      stepper-line-width: 5.625rem,

      // Label
      stepper-label-color: cv.getcssvar($name: scheme-base),
      stepper-label-font-size: cv.getcssvar($name: 'size-1'),
      stepper-label-font-weight: cv.getcssvar($name: 'weight-regular'),
      stepper-label-margin-top: 1rem,
      stepper-label-line-height: 1.75rem,

      // State: active
      stepper-active-line-background-color: cv.getcssvar($name: primary-color),
      stepper-active-link-border-color: cv.getcssvar($name: primary-color),
      stepper-active-link-color: cv.getcssvar($name: primary-color),
      stepper-active-link-font-weight: cv.getcssvar($name: weight-medium),
      stepper-active-label-font-weight: cv.getcssvar($name: weight-medium),
      stepper-active-label-color: cv.getcssvar($name: primary-color),

      // State: complete
      stepper-complete-line-background-color: cv.getcssvar($name: primary-color),
      stepper-complete-link-background-color: cv.getcssvar($name: primary-color),
      stepper-complete-link-border-color: cv.getcssvar($name: primary-color),
      stepper-complete-link-color: cv.getcssvar($name: primary-color-invert),

      // Variation: vertical
      stepper-vertical-label-margin-bottom: 2rem,
      stepper-vertical-label-padding-horizontal: 1rem,
      stepper-vertical-label-padding-vertical: calc(
        calc(
            #{cv.getcssvar($name: stepper-link-dimensions)} - #{cv.getcssvar(
                $name: stepper-label-line-height
              )}
          ) / 2
      ),

      // State: hover
      stepper-link-hover-background-color: cv.getcssvar($name: primary-80),
      stepper-link-hover-border-color: cv.getcssvar($name: primary-80),
      stepper-link-hover-box-shadow: 0 0 0 0.25rem cv.getcssvar($name: scheme-rgba05),

      // State: focus
      stepper-link-focus-background-color: cv.getcssvar($name: primary-80),
      stepper-link-focus-border-color: cv.getcssvar($name: focus-alt-shadow-color),
      stepper-link-focus-border-width: 2px,
      stepper-link-focus-box-shadow: (
        0 0 0 3px cv.getcssvar($name: focus-shadow-color)
      ),

    ),
    $is-global-scope: false
  );

  align-items: flex-start;
  color: cv.getcssvar($name: stepper-color);
  display: flex;
  justify-content: center;
  list-style-type: none;
  text-align: center;

  .#{config.$framework-prefix}-stepper-graph {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;

    &::before,
    &::after {
      background-color: cv.getcssvar($name: stepper-line-background-color);
      content: '';
      display: block;
      flex-grow: 1;
      height: cv.getcssvar($name: stepper-line-height);
    }

    &::before {
      border-bottom-right-radius: cv.getcssvar($name: stepper-line-radius);
      border-top-right-radius: cv.getcssvar($name: stepper-line-radius);
      margin-right: cv.getcssvar($name: stepper-item-spacing);
    }

    &::after {
      border-bottom-left-radius: cv.getcssvar($name: stepper-line-radius);
      border-top-left-radius: cv.getcssvar($name: stepper-line-radius);
      margin-left: cv.getcssvar($name: stepper-item-spacing);
    }
  }

  .#{config.$framework-prefix}-stepper-link {
    align-items: center;
    background-color: cv.getcssvar($name: stepper-link-background-color);
    border-color: cv.getcssvar($name: stepper-link-border-color);
    border-radius: cv.getcssvar($name: stepper-link-border-radius);
    border-style: cv.getcssvar($name: stepper-link-border-style);
    border-width: cv.getcssvar($name: stepper-link-border-width);
    box-shadow: cv.getcssvar($name: stepper-link-box-shadow);
    color: cv.getcssvar($name: stepper-link-color);
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: cv.getcssvar($name: stepper-link-font-size);
    font-weight: cv.getcssvar($name: stepper-link-font-weight);
    height: cv.getcssvar($name: stepper-link-dimensions);
    justify-content: center;
    outline: none;
    text-decoration: none;
    width: cv.getcssvar($name: stepper-link-dimensions);
  }

  .#{config.$framework-prefix}-stepper-label {
    color: cv.getcssvar($name: stepper-label-color);
    display: block;
    font-size: cv.getcssvar($name: 'stepper-label-font-size');
    font-weight: cv.getcssvar($name: 'stepper-label-font-weight');
    line-height: cv.getcssvar($name: 'stepper-label-line-height');
    margin-top: cv.getcssvar($name: stepper-label-margin-top);
    padding: 0 cv.getcssvar($name: stepper-item-spacing);
  }

  .#{config.$framework-prefix}-stepper-item {
    width: cv.getcssvar($name: stepper-item-width);

    &.#{config.$framework-prefix}-is-active {
      @include cv.cssvars(
        (
          stepper-link-border-color: cv.getcssvar($name: stepper-active-link-border-color),
          stepper-link-color: cv.getcssvar($name: stepper-active-link-color),
          stepper-link-font-weight: cv.getcssvar($name: stepper-active-link-font-weight),
          stepper-label-color: cv.getcssvar($name: stepper-active-label-color),
          stepper-label-font-weight: cv.getcssvar($name: stepper-active-label-font-weight),
        ),
        $is-global-scope: false
      );

      .#{config.$framework-prefix}-stepper-graph::before {
        background-color: cv.getcssvar($name: stepper-active-line-background-color);
      }
    }

    &.#{config.$framework-prefix}-is-complete {
      @include cv.cssvars(
        (
          stepper-line-background-color: cv.getcssvar($name: stepper-complete-link-background-color),
          stepper-link-background-color: cv.getcssvar($name: stepper-complete-link-background-color),
          stepper-link-border-color: cv.getcssvar($name: stepper-complete-link-border-color),
          stepper-link-color: cv.getcssvar($name: stepper-complete-link-color),
        ),
        $is-global-scope: false
      );

      .#{config.$framework-prefix}-stepper-link,
      .#{config.$framework-prefix}-stepper-label {
        cursor: pointer;
      }

      &:hover {
        @include cv.cssvars(
          (
            stepper-link-background-color: cv.getcssvar($name: stepper-link-hover-background-color),
            stepper-link-border-color: cv.getcssvar($name: stepper-link-hover-border-color),
            stepper-link-box-shadow: cv.getcssvar($name: stepper-link-hover-box-shadow),
          ),
          $is-global-scope: false
        );
      }

      .#{config.$framework-prefix}-stepper-link:focus {
        @include cv.cssvars(
          (
            stepper-link-background-color: cv.getcssvar($name: stepper-link-focus-background-color),
            stepper-link-border-color: cv.getcssvar($name: stepper-link-focus-border-color),
            stepper-link-border-width: cv.getcssvar($name: stepper-link-focus-border-width),
            stepper-link-box-shadow: cv.getcssvar($name: stepper-link-focus-box-shadow),
          ),
          $is-global-scope: false
        );
      }
    }
  }

  li:first-child .#{config.$framework-prefix}-stepper-graph::before,
  li:last-child .#{config.$framework-prefix}-stepper-graph::after {
    visibility: hidden;
  }

  // Variation: left
  &.#{config.$framework-prefix}-is-left {
    justify-content: flex-start;

    .#{config.$framework-prefix}-stepper-item {
      &:not(:last-child) {
        margin-right: cv.getcssvar($name: stepper-item-spacing);
      }
    }

    .#{config.$framework-prefix}-stepper-graph {
      &::before {
        display: none;
      }

      &::after {
        border-radius: cv.getcssvar($name: stepper-line-radius);
      }
    }

    .#{config.$framework-prefix}-stepper-label {
      padding-left: 0;
      text-align: left;
    }
  }

  // Variation: right
  &.#{config.$framework-prefix}-is-right {
    justify-content: flex-end;

    .#{config.$framework-prefix}-stepper-item {
      &:not(:first-child) {
        margin-left: cv.getcssvar($name: stepper-item-spacing);
      }
    }

    .#{config.$framework-prefix}-stepper-graph {
      &::before {
        border-radius: cv.getcssvar($name: stepper-line-radius);
      }

      &::after {
        display: none;
      }
    }

    .#{config.$framework-prefix}-stepper-label {
      padding-right: 0;
      text-align: right;
    }
  }

  // Variation: vertical
  &.#{config.$framework-prefix}-is-vertical {
    display: block;
    text-align: left;

    .#{config.$framework-prefix}-stepper-item {
      align-items: stretch;
      display: flex;
      width: auto;
    }

    .#{config.$framework-prefix}-stepper-graph {
      display: flex;
      flex-direction: column;

      &::before {
        display: none;
      }

      &::after {
        border-radius: cv.getcssvar($name: stepper-line-radius);
        margin: calc(#{cv.getcssvar($name: stepper-item-spacing)} / 2) 0;
        width: cv.getcssvar($name: stepper-line-height);
      }
    }

    .#{config.$framework-prefix}-stepper-label {
      margin: 0;
      margin-bottom: cv.getcssvar($name: stepper-vertical-label-margin-bottom);
      padding-bottom: cv.getcssvar($name: stepper-vertical-label-padding-vertical);
      padding-left: cv.getcssvar($name: stepper-vertical-label-padding-horizontal);
      padding-right: cv.getcssvar($name: stepper-vertical-label-padding-horizontal);
      padding-top: cv.getcssvar($name: stepper-vertical-label-padding-vertical);
    }
  }
}
