@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

.#{config.$framework-prefix}-dropdown {
  // prettier-ignore
  @include cv.cssvars(
    (
      dropdown-background-color: cv.getcssvar($name: 'scheme-main'),
      dropdown-border-radius: 0.25rem,
      dropdown-border-style: solid,
      dropdown-border-color: cv.getcssvar($name: 'border-bis'),
      dropdown-border-width: 1px,
      dropdown-shadow-color: cv.getcssvarfrommap($map: config.$shadows, $key: 'shadow-regular'),
      dropdown-box-shadow: 0px 2px cv.getcssvar($name: dropdown-shadow-color),
      dropdown-narrow-max-width: 10em,
      dropdown-item-line-height: cv.getcssvar($name: line-height-s),
      dropdown-item-padding: 0.75em 1em,
      dropdown-item-even-background-color: cv.getcssvar($name: primary-05),
      dropdown-item-hover-background-color: cv.getcssvar($name: scheme-05),
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: dropdown-background-color);
  border-color: cv.getcssvar($name: dropdown-border-color);
  border-radius: cv.getcssvar($name: dropdown-border-radius);
  border-style: cv.getcssvar($name: dropdown-border-style);
  border-width: cv.getcssvar($name: dropdown-border-width);
  box-shadow: cv.getcssvar($name: dropdown-box-shadow);
  min-width: 8rem;
  &.#{config.$framework-prefix}-is-narrow {
    max-width: cv.getcssvar($name: dropdown-narrow-max-width);
  }

  &.#{config.$framework-prefix}-is-clipped {
    flex-grow: 0;
    flex-shrink: 0;

    .#{config.$framework-prefix}-dropdown-item-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.#{config.$framework-prefix}-dropdown-item {
  @extend %unselectable;

  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: cv.getcssvar($name: dropdown-item-line-height);
  padding: cv.getcssvar($name: dropdown-item-padding);
  width: 100%;

  &.#{config.$framework-prefix}-is-selected {
    background-color: cv.getcssvar($name: dropdown-item-even-background-color);
  }

  &:hover {
    background-color: cv.getcssvar($name: dropdown-item-hover-background-color);
  }

  .#{config.$framework-prefix}-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 0.5em;
  }
}

.#{config.$framework-prefix}-dropdown-item-content {
  flex-grow: 1;
  flex-shrink: 1;
}
