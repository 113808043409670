@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our List
/// @group List
/// @name List
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-list {
  // prettier-ignore
  @include cv.cssvars(
    (
      item-border-color: cv.getcssvar($name: 'scheme-20'),
      item-border-style: solid,
      item-border-width: 2px,
      item-padding-horizontal: 1.25rem,
      item-padding-vertical: 1rem,

      // Child: icon
      icon-color: cv.getcssvar($name: 'primary-color'),

      // Child: title
      title-color: cv.getcssvar($name: 'scheme-100'),
      title-font-size: cv.getcssvar($name: 'size-05'),
      title-font-weight: cv.getcssvar($name: 'weight-medium'),

      // Child: body
      body-color: cv.getcssvar($name: 'scheme-base'),

      // State: hover
      item-hover-background-color: cv.getcssvar($name: 'scheme-rgba05'),
    ),
    $is-global-scope: false
  );

  &.#{config.$framework-prefix}-is-borderless {
    .#{config.$framework-prefix}-list-item {
      border-bottom: none;
    }
  }
}

.#{config.$framework-prefix}-list-item {
  align-items: center;
  background-color: cv.getcssvar($name: 'item-background-color');
  border-bottom-color: cv.getcssvar($name: 'item-border-color');
  border-bottom-style: cv.getcssvar($name: 'item-border-style');
  border-bottom-width: cv.getcssvar($name: 'item-border-width');
  display: flex;
  padding: cv.getcssvar($name: 'item-padding-vertical')
    cv.getcssvar($name: 'item-padding-horizontal');

  > .#{config.$framework-prefix}-icon,
  > .#{config.$framework-prefix}-checkbox {
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    min-width: cv.getcssvar($name: 'item-padding-horizontal');

    &:first-child {
      margin-right: cv.getcssvar($name: 'item-padding-horizontal');
    }

    &:last-child {
      color: cv.getcssvar($name: 'icon-color');
      margin-left: cv.getcssvar($name: 'item-padding-horizontal');
    }
  }

  &:hover {
    @include cv.cssvar(item-background-color, cv.getcssvar($name: 'item-hover-background-color'));
  }
}

label.#{config.$framework-prefix}-list-item {
  cursor: pointer;
}

.#{config.$framework-prefix}-list-item-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.#{config.$framework-prefix}-list-item-title {
  color: cv.getcssvar($name: 'title-color');
  font-size: cv.getcssvar($name: 'title-font-size');
  font-weight: cv.getcssvar($name: 'title-font-weight');
}

.#{config.$framework-prefix}-list-item-body {
  color: cv.getcssvar($name: 'body-color');
}
