@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Card
/// @group Layout
/// @name Card
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-card {
  @include cv.cssvars(
    (
      card-background-color: cv.getcssvar($name: 'scheme-main'),
      card-border-color: cv.getcssvar($name: 'scheme-100-rgba20'),
      card-border-radius: 0.25rem,
      card-border-style: solid,
      card-border-width: 1px,
      card-content-padding: 1.25rem,
      card-item-spacing: 1rem,
      card-heading-color: cv.getcssvar($name: 'scheme-100'),
      card-heading-font-size: cv.getcssvar($name: 'size-05'),
      card-heading-font-weight: cv.getcssvar($name: 'weight-medium'),
      card-subheading-color: cv.getcssvar($name: 'scheme-base'),
      card-action-icon-color: cv.getcssvar($name: scheme-base),
      card-action-icon-font-size: 1.5rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: card-background-color);
  border-color: cv.getcssvar($name: card-border-color);
  border-radius: cv.getcssvar($name: card-border-radius);
  border-style: cv.getcssvar($name: card-border-style);
  border-width: cv.getcssvar($name: card-border-width);
  overflow: hidden;

  &.#{config.$framework-prefix}-is-compact {
    @include cv.cssvars(
      (
        card-content-padding: 1rem,
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-landscape {
    display: flex;
    flex-direction: row;

    .#{config.$framework-prefix}-card-image {
      width: 7.5rem;
    }
  }
}

.#{config.$framework-prefix}-card-content {
  flex-grow: 1;
  flex-shrink: 1;
  padding: cv.getcssvar($name: card-content-padding);
}

.#{config.$framework-prefix}-card-image {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.#{config.$framework-prefix}-card-image-figure {
  img {
    height: 100%;
    left: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.#{config.$framework-prefix}-is-cover {
    img {
      object-fit: cover;
    }
  }

  &.#{config.$framework-prefix}-is-ratio-5by2 {
    padding-top: (2 / 5 * 100%);
  }

  &.#{config.$framework-prefix}-is-ratio-5by3 {
    padding-top: (3 / 5 * 100%);
  }

  &.#{config.$framework-prefix}-is-ratio-5by4 {
    padding-top: (4 / 5 * 100%);
  }

  &.#{config.$framework-prefix}-is-ratio-1by1,
  &.#{config.$framework-prefix}-is-ratio-square {
    padding-top: 100%;
  }
}

.#{config.$framework-prefix}-card-item,
.#{config.$framework-prefix}-card-header,
.#{config.$framework-prefix}-card-body,
.#{config.$framework-prefix}-card-footer {
  &:not(:first-child) {
    margin-top: cv.getcssvar($name: 'card-item-spacing');
  }

  &:not(:last-child) {
    margin-bottom: cv.getcssvar($name: 'card-item-spacing');
  }
}

.#{config.$framework-prefix}-card-header {
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: cv.getcssvar($name: 'card-item-spacing');
  }
}

.#{config.$framework-prefix}-card-figure {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin-right: cv.getcssvar($name: 'card-item-spacing');

  img {
    display: block;
    max-height: 3rem;
    max-width: 3rem;
  }
}

.#{config.$framework-prefix}-card-headings {
  flex-grow: 1;
  flex-shrink: 1;
}

.#{config.$framework-prefix}-card-heading {
  color: cv.getcssvar($name: 'card-heading-color');
  font-size: cv.getcssvar($name: 'card-heading-font-size');
  font-weight: cv.getcssvar($name: 'card-heading-font-weight');
}

.#{config.$framework-prefix}-card-subheading {
  color: cv.getcssvar($name: 'card-subheading-color');
}

.#{config.$framework-prefix}-card-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.#{config.$framework-prefix}-card-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > a {
    padding: 0.5em 0;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.#{config.$framework-prefix}-card-actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: auto;

  > .#{config.$framework-prefix}-icon {
    color: cv.getcssvar($name: card-action-icon-color);
    font-size: cv.getcssvar($name: card-action-icon-font-size);

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
