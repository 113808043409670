@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Split Button
/// @group Elements
/// @name Split Button
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-split-button {
  display: flex;

  &.#{config.$framework-prefix}-is-small {
    .#{config.$framework-prefix}-button {
      @include cv.cssvars(
        (
          button-font-size: cv.getcssvar($name: 'button-small-font-size'),
          button-padding-horizontal: cv.getcssvar($name: 'button-small-padding-horizontal'),
          button-padding-vertical: cv.getcssvar($name: 'button-small-padding-vertical'),
          icon-font-size: cv.getcssvar($name: 'icon-small-font-size'),
        ),
        $is-global-scope: false
      );
    }
  }

  &.#{config.$framework-prefix}-is-disabled {
    .#{config.$framework-prefix}-button {
      opacity: cv.getcssvar($name: 'button-disabled-opacity');
      pointer-events: none;
    }
  }

  &.#{config.$framework-prefix}-is-large {
    .#{config.$framework-prefix}-button {
      @include cv.cssvars(
        (
          button-font-size: cv.getcssvar($name: 'button-large-font-size'),
          button-padding-horizontal: cv.getcssvar($name: 'button-large-padding-horizontal'),
          button-padding-vertical: cv.getcssvar($name: 'button-large-padding-vertical'),
          icon-font-size: cv.getcssvar($name: 'icon-large-font-size'),
        ),
        $is-global-scope: false
      );
    }
  }

  .#{config.$framework-prefix}-button {
    position: relative;

    &::before,
    &::after {
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 1px;
    }

    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      &::before {
        background-color: rgba(255, 255, 255, 0.25);
        left: 0;
      }
    }

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      &::after {
        background-color: rgba(0, 0, 0, 0.25);
        right: 0;
      }
    }
  }
}

.#{config.$framework-prefix}-is-split-icon {
  @include cv.cssvars(
    (
      icon-margin: 0,
    ),
    $is-global-scope: false
  );

  padding-left: cv.getcssvar($name: button-padding-vertical);
  padding-right: cv.getcssvar($name: button-padding-vertical);

  .#{config.$framework-prefix}-icon {
    width: cv.getcssvar($name: button-line-height);
  }
}
