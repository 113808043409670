@use 'sass:map';
@use 'config';
@use 'helpers/css-variables' as cv;

body {
  @include cv.cssvars(
    (
      body-background-color: cv.getcssvar($name: 'scheme-main'),
      body-color: cv.getcssvar($name: 'text'),
      body-font-family: cv.getcssvar($name: 'base-font-family'),
      body-font-size: cv.getcssvar($name: 'base-font-size'),
      body-line-height: cv.getcssvar($name: 'base-line-height'),
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: 'body-background-color');
  color: cv.getcssvar($name: 'body-color');
  font-family: cv.getcssvar($name: 'body-font-family');
  font-size: cv.getcssvar($name: 'body-font-size');
  line-height: cv.getcssvar($name: 'body-line-height');
}

input,
button,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: cv.getcssvar($name: 'cta-color');
  text-decoration: none;
}

@each $name in map.keys(config.$utility-colors) {
  .#{config.$framework-prefix}-status-#{$name} {
    @include cv.cssvars(
      (
        status-base: cv.getcssvar($name: 'status-#{$name}-base'),
        status-alt: cv.getcssvar($name: 'status-#{$name}-alt'),
        status-ter: cv.getcssvar($name: 'status-#{$name}-ter'),
      ),
      $is-global-scope: false
    );
  }
}

:focus {
  outline: none;
}
