@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;

/// Our Overlay
/// @group Overlay
/// @name Overlay
/// @since 0.0.1
/// @author Simon Groenborg
.#{config.$framework-prefix}-overlay {
  @include cv.cssvars(
    (
      overlay-background-color: cv.getcssvar($name: 'scheme-rgba80'),
      overlay-container-top: 5rem,
      overlay-container-width: 31rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: overlay-background-color);
  bottom: 0;
  display: none;
  left: 0;
  overflow-y: auto;
  right: 0;
  top: 0;
  z-index: 1000;

  &.#{config.$framework-prefix}-is-open {
    display: block;
  }

  &.#{config.$framework-prefix}-overlay-page {
    position: fixed;
  }

  &.#{config.$framework-prefix}-overlay-container {
    position: absolute;
  }
}
