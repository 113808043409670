@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

$progress-indeterminate-duration: 1.5s;

/// Our Progress Bar
/// @group Form
/// @name Progress Bar
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-progress {
  // prettier-ignore
  @include cv.cssvars(
    (
      value-font-weight: cv.getcssvar($name: 'weight-strong'),
      value-margin-bottom: 0.5rem,
      bar-height: 0.25rem,
      bar-border-radius: cv.getcssvar($name: 'radius-rounded'),
      bar-background-color: cv.getcssvar($name: 'scheme-rgba10'),
      bar-value-background-color: cv.getcssvar($name: 'primary-color'),
    ),
    $is-global-scope: false
  );

  color: cv.getcssvar($name: 'progress-color');
  font-size: cv.getcssvar($name: 'progress-font-size');
}

.#{config.$framework-prefix}-progress-value {
  font-weight: cv.getcssvar($name: 'value-font-weight');
  margin-bottom: cv.getcssvar($name: 'value-margin-bottom');
}

.#{config.$framework-prefix}-progress-bar {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: cv.getcssvar($name: 'bar-border-radius');
  display: block;
  height: cv.getcssvar($name: 'bar-height');
  overflow: hidden;
  padding: 0;
  width: 100%;

  &::-webkit-progress-bar {
    background-color: cv.getcssvar($name: 'bar-background-color');
  }

  &::-webkit-progress-value {
    background-color: cv.getcssvar($name: 'bar-value-background-color');
  }

  &::-moz-progress-bar {
    background-color: cv.getcssvar($name: 'value-background-color');
  }

  &::-ms-fill {
    background-color: cv.getcssvar($name: 'value-background-color');
    border: none;
  }

  &:indeterminate {
    animation-duration: $progress-indeterminate-duration;
    animation-iteration-count: infinite;
    animation-name: move-indeterminate;
    animation-timing-function: linear;
    background-color: cv.getcssvar($name: 'bar-background-color');
    background-image: linear-gradient(
      to right,
      cv.getcssvar($name: 'bar-value-background-color') 30%,
      cv.getcssvar($name: 'bar-background-color') 30%
    );
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 150% 150%;

    &::-webkit-progress-bar {
      background-color: transparent;
    }

    &::-moz-progress-bar {
      background-color: transparent;
    }

    &::-ms-fill {
      animation-name: none;
    }
  }
}

.#{config.$framework-prefix}-progress-ring {
  // prettier-ignore
  @include cv.cssvars(
    (

      circle-full-diameter: 60,
      circle-stroke-width: 4,
      circle-inner-diameter: 56,
      circle-stroke-circumference: calc(#{cv.getcssvar($name: 'circle-inner-diameter')} * 3.1415),

      // Only update this value
      circle-stroke-percentage: 43,

      // Used in HTML
      circle-stroke-border: cv.getcssvar($name: 'bar-background-color'),
      circle-stroke-color: cv.getcssvar($name: 'bar-value-background-color'),
      circle-stroke-dasharray: (cv.getcssvar($name: 'circle-stroke-circumference') cv.getcssvar($name: 'circle-stroke-circumference')),
      circle-stroke-dashoffset: calc(
        #{cv.getcssvar($name: 'circle-stroke-circumference')} -
        calc(
          #{cv.getcssvar($name: 'circle-stroke-circumference')} *
          #{cv.getcssvar($name: 'circle-stroke-percentage')} /
          100
        )
      ),
    ),
    $is-global-scope: false
  );

  height: 60px;
  position: relative;
  width: 60px;

  .#{config.$framework-prefix}-progress-value {
    left: 0;
    line-height: 1.5;
    margin-bottom: 0;
    position: absolute;
    text-align: center;
    top: calc(50% - 0.75em);
    width: 100%;
  }

  &.#{config.$framework-prefix}-is-small {
    // prettier-ignore
    @include cv.cssvars(
      (
        circle-full-diameter: 40,
        circle-stroke-width: 4,
        circle-inner-diameter: 36,
      ),
      $is-global-scope: false
    );

    height: 40px;
    width: 40px;

    .#{config.$framework-prefix}-progress-value {
      display: none;
    }
  }

  &.#{config.$framework-prefix}-is-large {
    // prettier-ignore
    @include cv.cssvars(
      (
        circle-full-diameter: 100,
        circle-stroke-width: 4,
        circle-inner-diameter: 96,
      ),
      $is-global-scope: false
    );

    height: 100px;
    width: 100px;
  }
}

.#{config.$framework-prefix}-progress-ring-svg {
  transform: rotate(-90deg);
  transform-origin: center;
}

@keyframes move-indeterminate {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}
