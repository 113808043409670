@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Sidebar
/// @group Navigation
/// @name Sidebar
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-sidebar {
  // prettier-ignore
  @include cv.cssvars(
    (
      sidebar-background-color: cv.getcssvar($name: 'primary-color'),
      sidebar-color: cv.getcssvar($name: 'primary-color-invert'),
      sidebar-padding: 1.5rem,
      sidebar-width: 15rem,

      // Group
      sidebar-group-margin-bottom: 1.5rem,

      // Divider
      sidebar-divider-background-color: cv.getcssvar($name: 'primary-color-invert'),
      sidebar-divider-opacity: 0.2,
      sidebar-divider-height: 2px,

      // Logo
      sidebar-logo-dimensions: 2.5rem,
      sidebar-logo-margin-right: 1.25rem,

      // Name
      sidebar-name-font-size: cv.getcssvar($name: 'size-2'),
      sidebar-name-font-weight: cv.getcssvar($name: 'weight-medium'),

      // Link
      sidebar-link-font-size: cv.getcssvar($name: 'size-1'),
      sidebar-link-padding-vertical: 1rem,
      sidebar-link-padding-horizontal: 1.5rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: sidebar-background-color);
  color: cv.getcssvar($name: sidebar-color);
  padding: cv.getcssvar($name: sidebar-padding);
  width: cv.getcssvar($name: sidebar-width);
}

.#{config.$framework-prefix}-sidebar-group,
.#{config.$framework-prefix}-sidebar-divider,
.#{config.$framework-prefix}-sidebar-menu {
  &:not(:last-child) {
    margin-bottom: cv.getcssvar($name: sidebar-group-margin-bottom);
  }
}

.#{config.$framework-prefix}-sidebar-divider {
  background-color: cv.getcssvar($name: sidebar-divider-background-color);
  height: cv.getcssvar($name: sidebar-divider-height);
  opacity: cv.getcssvar($name: sidebar-divider-opacity);
}

.#{config.$framework-prefix}-sidebar-brand {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.#{config.$framework-prefix}-sidebar-logo {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: cv.getcssvar($name: sidebar-logo-margin-right);
  width: cv.getcssvar($name: sidebar-logo-dimensions);

  svg {
    flex-grow: 1;
  }
}

.#{config.$framework-prefix}-sidebar-name {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: cv.getcssvar($name: sidebar-name-font-size);
  font-weight: cv.getcssvar($name: sidebar-name-font-weight);
  text-align: right;
}

.#{config.$framework-prefix}-sidebar-menu {
  margin-left: calc(-1 * #{cv.getcssvar($name: sidebar-padding)});
  margin-right: calc(-1 * #{cv.getcssvar($name: sidebar-padding)});
}

.#{config.$framework-prefix}-sidebar-link {
  display: block;
  font-size: cv.getcssvar($name: sidebar-link-font-size);
  padding: cv.getcssvar($name: sidebar-link-padding-vertical)
    cv.getcssvar($name: sidebar-link-padding-horizontal);
}
