@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Switch
/// @group Form
/// @name Switch
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-switch {
  // prettier-ignore
  @include cv.cssvars(
    (
      switch-background-color: cv.getcssvar($name: 'scheme-20'),
      switch-shadow-color: cv.getcssvar($name: 'scheme-40'),
      switch-shadow-width: 1px,
      switch-shadow: inset 0 0 0 1px cv.getcssvar($name: 'switch-shadow-color'),
      switch-height: 1.5rem,
      switch-width: calc(2 * #{cv.getcssvar($name: 'switch-height')}),

      // State: hover
      switch-hover-background-color: cv.getcssvar($name: 'primary-color'),
      switch-hover-shadow-color: rgba(49, 49, 48, 0.4),

      // State: focus
      switch-focus-shadow: (
        0 0 0 2px cv.getcssvar($name: 'focus-inner-color'),
        0 0 0 4px cv.getcssvar($name: 'focus-outer-color'),
      ),

      // Dot
      dot-background-color: cv.getcssvar($name: 'primary-color-invert'),
      dot-box-shadow: 1px 0px 0px cv.getcssvar('shadow-color'),
      dot-active-box-shadow: -1px 0px 0px cv.getcssvar('shadow-color'),
      dot-dimensions: calc(#{cv.getcssvar($name: 'switch-height')} - (2 * #{cv.getcssvar($name: 'dot-spacing')})),
      dot-spacing: 2px,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: 'switch-background-color');
  border-radius: cv.getcssvar($name: 'radius-rounded');
  box-shadow: cv.getcssvar($name: 'switch-shadow');
  cursor: pointer;
  height: cv.getcssvar($name: 'switch-height');
  outline: none;
  position: relative;
  width: cv.getcssvar($name: 'switch-width');

  &.#{config.$framework-prefix}-is-active {
    // prettier-ignore
    @include cv.cssvars(
      (
        switch-background-color: cv.getcssvar($name: 'switch-hover-background-color'),
        switch-shadow-color: cv.getcssvar($name: 'switch-hover-shadow-color'),
        dot-box-shadow: cv.getcssvar($name: 'dot-active-box-shadow'),
      ),
      $is-global-scope: false
    );

    .#{config.$framework-prefix}-switch-dot {
      transform: translateX(cv.getcssvar($name: 'switch-height'));
    }
  }

  &:focus,
  &.#{config.$framework-prefix}-is-focused {
    // prettier-ignore
    @include cv.cssvars(
      (
        switch-shadow: cv.getcssvar($name: 'switch-focus-shadow'),
      ),
      $is-global-scope: false
    );
  }

  &[disabled],
  &.#{config.$framework-prefix}-is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.#{config.$framework-prefix}-switch-dot {
  background-color: cv.getcssvar($name: 'dot-background-color');
  border-radius: cv.getcssvar($name: 'radius-rounded');
  box-shadow: cv.getcssvar($name: 'dot-box-shadow');
  height: cv.getcssvar($name: 'dot-dimensions');
  left: cv.getcssvar($name: 'dot-spacing');
  position: absolute;
  top: cv.getcssvar($name: 'dot-spacing');
  width: cv.getcssvar($name: 'dot-dimensions');
}
