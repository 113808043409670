@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Button
/// @group Elements
/// @name Button
/// @since 0.0.1
/// @author Vincent Briglia
.#{config.$framework-prefix}-buttons {
  @include cv.cssvars(
    (
      buttons-item-spacing: 1rem,
    ),
    $is-global-scope: false
  );

  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(-1 * #{cv.getcssvar($name: buttons-item-spacing)});

  .#{config.$framework-prefix}-button {
    margin-bottom: cv.getcssvar($name: buttons-item-spacing);
  }

  &:not(.#{config.$framework-prefix}-is-end) {
    justify-content: flex-start;

    .#{config.$framework-prefix}-button {
      &:not(:last-child) {
        margin-right: cv.getcssvar($name: buttons-item-spacing);
      }
    }
  }

  &.#{config.$framework-prefix}-is-end {
    flex-direction: row-reverse;

    .#{config.$framework-prefix}-button {
      &:not(:first-child) {
        margin-right: cv.getcssvar($name: buttons-item-spacing);
      }
    }
  }
}

.#{config.$framework-prefix}-button {
  /// Local css vars for button, derived.
  /// always first
  // prettier-ignore
  @include cv.cssvars(
    (
      button-base-color: cv.getcssvar($name: 'cta-color'),
      button-base-color-invert: cv.getcssvar($name: 'cta-color-invert'),
      button-alt-color: cv.getcssvar($name: 'cta-alt-color'),
      button-alt-color-invert: cv.getcssvar($name: 'cta-alt-color-invert'),

      button-background-color: cv.getcssvar($name: 'button-base-color'),
      button-border-radius: 0.25rem,
      button-border-color: transparent,
      button-border-width: 0px,
      button-color: cv.getcssvar($name: 'button-base-color-invert'),
      button-font-size: cv.getcssvar($name: 'size-05'),
      button-line-height: 1.5rem,
      button-padding-vertical: 0.75rem,
      button-padding-horizontal: 1rem,
      button-inner-shadow-color: rgba(black, 0.25),
      button-inner-shadow: inset 0px -1px 0px cv.getcssvar($name: button-inner-shadow-color),
      button-outer-shadow-color: cv.getcssvar($name: shadow-color),
      button-outer-shadow: 0px 1px 0px cv.getcssvar($name: button-outer-shadow-color),
      button-box-shadow: (
        cv.getcssvar($name: button-outer-shadow),
        cv.getcssvar($name: button-inner-shadow)
      ),
      // Icon
      icon-margin: 0.5rem,
      icon-font-size: cv.getcssvar($name: 'size-0'),
      // State: hover
      button-hover-background-color: cv.getcssvar($name: 'button-alt-color'),
      // State: focus
      button-focus-background-color: cv.getcssvar($name: 'button-alt-color'),
      button-focus-box-shadow: (
        cv.getcssvar($name: button-outer-shadow),
        cv.getcssvar($name: focus-box-shadow)
      ),
      // State: active
      button-active-background-color: cv.getcssvar($name: 'button-alt-color'),
      button-active-outer-shadow: none,
      // State: disabled
      button-disabled-opacity: cv.getcssvar($name: 'disabled-opacity'),
      // Style: outilne
      button-outline-background-color: transparent,
      button-outline-border-color: cv.getcssvar($name: 'button-base-color'),
      button-outline-border-radius: 4px,
      button-outline-color: cv.getcssvar($name: 'button-base-color'),
      button-outline-border-width: 2px,
      button-outline-box-shadow: none,
      button-outline-hover-background-color: cv.getcssvar($name: 'scheme-rgba10'),
      button-outline-hover-color: cv.getcssvar($name: 'button-alt-color'),
      button-outline-focus-background-color: cv.getcssvar($name: 'scheme-rgba10'),
      button-outline-focus-box-shadow: (
        cv.getcssvar($name: focus-box-shadow)
      ),
      button-outline-focus-color: cv.getcssvar($name: 'button-alt-color'),
      button-outline-active-background-color: cv.getcssvar($name: 'scheme-rgba10'),
      button-outline-active-color: cv.getcssvar($name: 'button-alt-color'),
      // Style: outline reverse
      button-outline-reverse-hover-background-color: cv.getcssvar($name: 'scheme-rgba40'),
      button-outline-reverse-border-color: cv.getcssvar($name: 'button-base-color-invert'),
      button-outline-reverse-color: cv.getcssvar($name: 'button-base-color-invert'),
      button-outline-reverse-hover-color: cv.getcssvar($name: 'button-base-color-invert'),
      button-outline-reverse-active-background-color: cv.getcssvar($name: 'scheme-rgba40'),
      // Style: ghost
      button-ghost-background-color: transparent,
      button-ghost-box-shadow: none,
      button-ghost-color: cv.getcssvar($name: 'button-base-color'),
      button-ghost-hover-color: cv.getcssvar($name: 'button-alt-color'),
      button-ghost-hover-background-color: cv.getcssvar($name: 'scheme-rgba10'),
      button-ghost-focus-background-color: cv.getcssvar($name: 'scheme-rgba10'),
      button-ghost-focus-border-color: cv.getcssvar($name: 'button-base-color-invert'),
      button-ghost-focus-box-shadow: (
        cv.getcssvar($name: focus-box-shadow)
      ),
      button-ghost-active-background-color: cv.getcssvar($name: 'scheme-rgba10'),
      button-ghost-active-inner-shadow-color: cv.getcssvar($name: shadow-color),
      button-ghost-active-inner-shadow: inset 0px 2px 0px cv.getcssvar($name: button-ghost-active-inner-shadow-color),
      button-ghost-active-box-shadow: (
        cv.getcssvar($name: button-ghost-active-inner-shadow)
      ),
      // Style: ghost reverse
      button-ghost-reverse-color: cv.getcssvar($name: 'button-base-color-invert'),
      button-ghost-reverse-hover-background-color: cv.getcssvar($name: 'scheme-rgba40'),
      button-ghost-reverse-focus-background-color: cv.getcssvar($name: 'scheme-rgba40'),
      button-ghost-reverse-active-background-color: cv.getcssvar($name: 'scheme-rgba40'),
      // Style: plain
      button-plain-background-color: cv.getcssvar($name: scheme-05),
      button-plain-color: cv.getcssvar($name: 'text'),
      button-plain-hover-background-color: cv.getcssvar($name: 'scheme-20'),
      button-plain-hover-color: cv.getcssvar($name: 'button-alt-color'),
      button-plain-active-background-color: cv.getcssvar($name: 'scheme-20'),
      button-plain-focus-background-color: cv.getcssvar($name: 'scheme-20'),
      // Style: icon-only
      button-icon-only-border-radius: cv.getcssvar($name: 'radius-rounded'),
      button-icon-only-dimensions: 3rem,
      button-icon-only-icon-font-size: cv.getcssvar($name: 'size-1'),
      button-icon-only-icon-margin: 0,
      // Size: small
      button-small-font-size: cv.getcssvar($name: 'size-0'),
      button-small-padding-vertical: 0.25rem,
      button-small-padding-horizontal: 0.75rem,
      icon-small-font-size: cv.getcssvar($name: 'size-m1'),
      button-small-icon-only-dimensions: 2rem,
      button-small-icon-only-icon-font-size: cv.getcssvar($name: 'size-0'),
      // Size: large
      button-large-font-size: cv.getcssvar($name: 'size-1'),
      button-large-padding-vertical: 1.25rem,
      button-large-padding-horizontal: 1.5rem,
      icon-large-font-size: cv.getcssvar($name: 'size-05'),
      icon-large-margin: 0.625rem,
      button-large-icon-only-dimensions: 4rem,
      button-large-icon-only-icon-font-size: 1.5rem,
    ),
    $is-global-scope: false
  );

  @extend %reset;
  @extend %unselectable;

  align-items: center;
  background-color: cv.getcssvar($name: button-background-color);
  border-color: cv.getcssvar($name: 'button-border-color');
  border-radius: cv.getcssvar($name: button-border-radius);
  border-style: solid;
  border-width: cv.getcssvar($name: 'button-border-width');
  box-shadow: cv.getcssvar($name: button-box-shadow);
  color: cv.getcssvar($name: button-color);
  cursor: pointer;
  display: flex;
  font-size: cv.getcssvar($name: 'button-font-size');
  height: auto;
  justify-content: center;
  line-height: cv.getcssvar($name: 'button-line-height');
  outline: none;
  padding: calc(
      #{cv.getcssvar($name: 'button-padding-vertical')} - #{cv.getcssvar(
          $name: 'button-border-width'
        )}
    )
    calc(
      #{cv.getcssvar($name: 'button-padding-horizontal')} - #{cv.getcssvar(
          $name: 'button-border-width'
        )}
    );
  text-align: center;

  // Icon
  .#{config.$framework-prefix}-icon {
    font-size: cv.getcssvar($name: 'icon-font-size');

    &:first-child {
      margin-right: cv.getcssvar($name: 'icon-margin');
    }

    &:last-child {
      margin-left: cv.getcssvar($name: 'icon-margin');
    }
  }

  // States
  &:hover,
  &.#{config.$framework-prefix}-is-hover {
    @include cv.cssvars(
      (
        button-background-color: cv.getcssvar($name: 'button-hover-background-color'),
      ),
      $is-global-scope: false
    );
  }

  &:focus,
  &.#{config.$framework-prefix}-is-focus {
    &:not(:active) {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-focus-background-color'),
          button-box-shadow: cv.getcssvar($name: 'button-focus-box-shadow'),
        ),
        $is-global-scope: false
      );

      outline: none;
    }
  }

  &:active,
  &.#{config.$framework-prefix}-is-active {
    @include cv.cssvars(
      (
        button-background-color: cv.getcssvar($name: 'button-active-background-color'),
        button-outer-shadow: cv.getcssvar($name: 'button-active-outer-shadow'),
      ),
      $is-global-scope: false
    );

    transform: translateY(1px);
  }

  &[disabled],
  &.#{config.$framework-prefix}-is-disabled {
    opacity: cv.getcssvar($name: 'button-disabled-opacity');
    pointer-events: none;

    .#{config.$framework-prefix}-icon {
      opacity: cv.getcssvar($name: 'button-disabled-opacity');
    }
  }

  // Styles
  &.#{config.$framework-prefix}-is-outline {
    @include cv.cssvars(
      (
        button-background-color: cv.getcssvar($name: 'button-outline-background-color'),
        button-border-width: cv.getcssvar($name: 'button-outline-border-width'),
        button-border-color: cv.getcssvar($name: 'button-outline-border-color'),
        button-border-radius: cv.getcssvar($name: 'button-outline-border-radius'),
        button-box-shadow: cv.getcssvar($name: 'button-outline-box-shadow'),
        button-color: cv.getcssvar($name: 'button-outline-color'),
      ),
      $is-global-scope: false
    );

    border-style: solid;

    &:hover,
    &.#{config.$framework-prefix}-is-hover {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-outline-hover-background-color'),
          button-color: cv.getcssvar($name: 'button-outline-hover-color'),
        ),
        $is-global-scope: false
      );
    }

    &:focus,
    &.#{config.$framework-prefix}-is-focus {
      &:not(:active) {
        @include cv.cssvars(
          (
            button-background-color: cv.getcssvar($name: 'button-outline-focus-background-color'),
            button-box-shadow: cv.getcssvar($name: 'button-outline-focus-box-shadow'),
            button-color: cv.getcssvar($name: 'button-outline-focus-color'),
          ),
          $is-global-scope: false
        );
      }
    }

    &:active,
    &.#{config.$framework-prefix}-is-active {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-outline-focus-background-color'),
          button-color: cv.getcssvar($name: 'button-outline-focus-color'),
        ),
        $is-global-scope: false
      );
    }

    &.#{config.$framework-prefix}-is-reverse {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-outline-reverse-background-color'),
          button-border-color: cv.getcssvar($name: 'button-outline-reverse-border-color'),
          button-color: cv.getcssvar($name: 'button-outline-reverse-color'),
        ),
        $is-global-scope: false
      );

      &:hover,
      &.#{config.$framework-prefix}-is-hover {
        @include cv.cssvars(
          (
            button-background-color:
              cv.getcssvar($name: 'button-outline-reverse-hover-background-color'),
          ),
          $is-global-scope: false
        );
      }

      &:focus,
      &.#{config.$framework-prefix}-is-focus {
        &:not(:active) {
          @include cv.cssvars(
            (
              button-background-color:
                cv.getcssvar($name: 'button-outline-reverse-hover-background-color'),
              button-color: cv.getcssvar($name: 'button-outline-reverse-hover-color'),
            ),
            $is-global-scope: false
          );
        }
      }

      &:active,
      &.#{config.$framework-prefix}-is-active {
        @include cv.cssvars(
          (
            button-background-color:
              cv.getcssvar($name: 'button-outline-reverse-active-background-color'),
          ),
          $is-global-scope: false
        );
      }
    }
  }

  &.#{config.$framework-prefix}-is-ghost {
    @include cv.cssvars(
      (
        button-background-color: cv.getcssvar($name: 'button-ghost-background-color'),
        button-box-shadow: cv.getcssvar($name: 'button-ghost-box-shadow'),
        button-color: cv.getcssvar($name: 'button-ghost-color'),
      ),
      $is-global-scope: false
    );

    &:hover,
    &.#{config.$framework-prefix}-is-hover {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-ghost-hover-background-color'),
        ),
        $is-global-scope: false
      );
    }

    &:focus,
    &.#{config.$framework-prefix}-is-focus {
      &:not(:active) {
        @include cv.cssvars(
          (
            button-background-color: cv.getcssvar($name: 'button-ghost-focus-background-color'),
            button-border-color: cv.getcssvar($name: 'button-ghost-focus-border-color'),
            button-box-shadow: cv.getcssvar($name: 'button-ghost-focus-box-shadow'),
          ),
          $is-global-scope: false
        );
      }
    }

    &:active,
    &.#{config.$framework-prefix}-is-active {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-ghost-active-background-color'),
          button-box-shadow: cv.getcssvar($name: 'button-ghost-active-box-shadow'),
        ),
        $is-global-scope: false
      );
    }

    &.#{config.$framework-prefix}-is-reverse {
      @include cv.cssvars(
        (
          button-color: cv.getcssvar($name: 'button-ghost-reverse-color'),
        ),
        $is-global-scope: false
      );

      &:hover,
      &.#{config.$framework-prefix}-is-hover {
        @include cv.cssvars(
          (
            button-background-color:
              cv.getcssvar($name: 'button-ghost-reverse-hover-background-color'),
          ),
          $is-global-scope: false
        );
      }

      &:focus,
      &.#{config.$framework-prefix}-is-focus {
        &:not(:active) {
          @include cv.cssvars(
            (
              button-background-color:
                cv.getcssvar($name: 'button-ghost-reverse-hover-background-color'),
            ),
            $is-global-scope: false
          );
        }
      }

      &:active,
      &.#{config.$framework-prefix}-is-active {
        @include cv.cssvars(
          (
            button-background-color:
              cv.getcssvar($name: 'button-ghost-reverse-active-background-color'),
          ),
          $is-global-scope: false
        );
      }
    }
  }

  &.#{config.$framework-prefix}-is-plain {
    @include cv.cssvars(
      (
        button-background-color: cv.getcssvar($name: 'button-plain-background-color'),
        button-color: cv.getcssvar($name: 'button-plain-color'),
        button-inner-shadow-color: rgba(black, 0.15),
      ),
      $is-global-scope: false
    );

    &:hover,
    &.#{config.$framework-prefix}-is-hover {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-plain-hover-background-color'),
        ),
        $is-global-scope: false
      );
    }

    &:focus,
    &.#{config.$framework-prefix}-is-focus {
      &:not(:active) {
        @include cv.cssvars(
          (
            button-background-color: cv.getcssvar($name: 'button-plain-focus-background-color'),
          ),
          $is-global-scope: false
        );
      }
    }

    &:active,
    &.#{config.$framework-prefix}-is-active {
      @include cv.cssvars(
        (
          button-background-color: cv.getcssvar($name: 'button-plain-active-background-color'),
        ),
        $is-global-scope: false
      );
    }
  }

  // Sizes
  &.#{config.$framework-prefix}-is-small {
    @include cv.cssvars(
      (
        button-font-size: cv.getcssvar($name: 'button-small-font-size'),
        button-padding-vertical: cv.getcssvar($name: 'button-small-padding-vertical'),
        button-padding-horizontal: cv.getcssvar($name: 'button-small-padding-horizontal'),
        icon-font-size: cv.getcssvar($name: 'icon-small-font-size'),
        button-icon-only-dimensions: cv.getcssvar($name: 'button-small-icon-only-dimensions'),
        button-icon-only-icon-font-size:
          cv.getcssvar($name: 'button-small-icon-only-icon-font-size'),
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-large {
    @include cv.cssvars(
      (
        button-font-size: cv.getcssvar($name: 'button-large-font-size'),
        button-padding-vertical: cv.getcssvar($name: 'button-large-padding-vertical'),
        button-padding-horizontal: cv.getcssvar($name: 'button-large-padding-horizontal'),
        icon-font-size: cv.getcssvar($name: 'icon-large-font-size'),
        icon-margin: cv.getcssvar($name: 'icon-large-margin'),
        button-icon-only-dimensions: cv.getcssvar($name: 'button-large-icon-only-dimensions'),
        button-icon-only-icon-font-size:
          cv.getcssvar($name: 'button-large-icon-only-icon-font-size'),
      ),
      $is-global-scope: false
    );
  }

  // Icon only
  &.#{config.$framework-prefix}-is-icon-only {
    @include cv.cssvars(
      (
        icon-margin: cv.getcssvar($name: 'button-icon-only-icon-margin'),
      ),
      $is-global-scope: false
    );

    height: cv.getcssvar($name: 'button-icon-only-dimensions');
    padding: 0;
    width: cv.getcssvar($name: 'button-icon-only-dimensions');

    &:not(.#{config.$framework-prefix}-is-plain) {
      @include cv.cssvars(
        (
          button-border-radius: cv.getcssvar($name: 'button-icon-only-border-radius'),
          icon-font-size: cv.getcssvar($name: 'button-icon-only-icon-font-size'),
        ),
        $is-global-scope: false
      );
    }
  }
}
