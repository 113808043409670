@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Breadcrumb
/// @group Navigation
/// @name Breadcrumb
/// @since 0.0.1
/// @author Jeremy Thomas
nav.#{config.$framework-prefix}-breadcrumb {
  // prettier-ignore
  @include cv.cssvars(
    (
      item-border-radius: 0.125rem,
      item-color: cv.getcssvar($name: 'primary-color'),
      item-font-size: cv.getcssvar($name: 'size-0'),
      item-font-weight: cv.getcssvar($name: 'weight-medium'),
      item-margin: 0.375rem,
      item-padding: 0.5rem,

      // Child: separator
      separator-font-size: 0.75rem,
      separator-color: cv.getcssvar($name: 'scheme-50'),

      // Size: small
      item-small-font-size: cv.getcssvar('size-m1'),

      // Size: large
      item-large-font-size: cv.getcssvar('size-05'),

      // State: hover
      item-hover-color: cv.getcssvar('primary-alt-color'),

      // State: current
      item-current-color: cv.getcssvar($name: 'scheme-100'),
      item-current-font-weight: cv.getcssvar($name: 'weight-normal'),

    ),
    $is-global-scope: false
  );

  ol {
    display: flex;
  }

  li {
    flex-shrink: 0;
    list-style: none;
  }

  &.#{config.$framework-prefix}-is-small {
    .#{config.$framework-prefix}-breadcrumb-item {
      @include cv.cssvar($name: 'item-font-size', $value: cv.getcssvar('item-small-font-size'));
    }
  }

  &.#{config.$framework-prefix}-is-large {
    .#{config.$framework-prefix}-breadcrumb-item {
      @include cv.cssvar($name: 'item-font-size', $value: cv.getcssvar('item-large-font-size'));
    }
  }
}

.#{config.$framework-prefix}-breadcrumb-item,
.#{config.$framework-prefix}-breadcrumb-separator {
  align-items: center;
  display: flex;
}

.#{config.$framework-prefix}-breadcrumb-item {
  color: cv.getcssvar($name: 'item-color');
  font-size: cv.getcssvar($name: 'item-font-size');
  font-weight: cv.getcssvar($name: 'item-font-weight');

  &:first-child {
    margin-left: calc(-1 * #{cv.getcssvar($name: 'item-padding')});
  }

  &:not(:last-child) {
    margin-right: cv.getcssvar($name: 'item-margin');
  }

  a {
    border: 1px solid transparent;
    border-radius: cv.getcssvar($name: 'item-border-radius');
    border-width: 1px 0;
    color: inherit;
    line-height: 1;
    padding: cv.getcssvar($name: 'item-padding');
    position: relative;

    &::after {
      background-color: transparent;
      bottom: calc(#{cv.getcssvar($name: 'item-padding')} - 2px);
      content: '';
      display: block;
      height: 1px;
      left: cv.getcssvar($name: 'item-padding');
      position: absolute;
      right: cv.getcssvar($name: 'item-padding');
    }

    &:focus {
      box-shadow: cv.getcssvar($name: focus-box-shadow);
      outline: none;
    }
  }

  &:hover {
    @include cv.cssvar($name: 'item-color', $value: cv.getcolor('item-hover-color'));

    a::after {
      background-color: currentColor;
    }
  }

  &.#{config.$framework-prefix}-is-current {
    @include cv.cssvar($name: 'item-color', $value: cv.getcssvar($name: 'item-current-color'));
    @include cv.cssvar(
      $name: 'item-font-weight',
      $value: cv.getcssvar($name: 'item-current-font-weight')
    );
  }
}

.#{config.$framework-prefix}-breadcrumb-separator {
  color: cv.getcssvar($name: 'separator-color');
  font-size: cv.getcssvar($name: 'separator-font-size');
  margin-left: cv.getcssvar($name: 'item-margin');
}
