@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

$cell-hover-background-color: rgba(
  map.get(
    $map: config.$neutral-colors,
    $key: 'slate-100',
  ),
  0.05
);

.#{config.$framework-prefix}-table-container {
  .#{config.$framework-prefix}-table {
    width: 100%;
  }
}

/// Our Table
/// @group Elements
/// @name Table
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-table {
  // prettier-ignore
  @include cv.cssvars(
    (
      thead-background-color: cv.getcssvar($name: 'scheme-10'),
      th-font-weight: cv.getcssvar($name: 'weight-medium'),
      row-alt-background-color: cv.getcssvar($name: 'scheme-02'),
      thead-row-border-color: cv.getcssvar($name: 'border-bis'),
      thead-row-border-style: solid,
      thead-row-border-width: 2px,
      row-border-color: cv.getcssvar($name: 'border-ter'),
      row-border-style: solid,
      row-border-width: 1px,
      row-hover-background-color: cv.getcssvar($name: 'scheme-05'),
      row-active-background-color: cv.getcssvar($name: 'primary-05'),
      cell-padding: 1em,
      cell-hover-background-color: $cell-hover-background-color,
      heading-focus-outline: 2px solid cv.getcssvar($name: 'focus-shadow-color'),
    ),
    $is-global-scope: false
  );

  border-collapse: collapse;

  [role='cell'],
  [role='columnheader'] {
    padding: cv.getcssvar($name: 'cell-padding');

    &.#{config.$framework-prefix}-align-left {
      text-align: left;
    }

    &.#{config.$framework-prefix}-align-right {
      text-align: right;
    }

    &.#{config.$framework-prefix}-align-center {
      text-align: center;
    }

    &.#{config.$framework-prefix}-is-numeric {
      font-feature-settings: 'tnum' on, 'lnum' on;
    }

    &.#{config.$framework-prefix}-is-hover {
      background-color: cv.getcssvar($name: 'cell-hover-background-color');
    }
  }

  [role='cell'] {
    .#{config.$framework-prefix}-icon {
      font-size: 0.75em;
      margin-right: 0.5em;
    }
  }

  [role='columnheader'] {
    font-weight: cv.getcssvar($name: 'th-font-weight');
  }

  thead {
    background-color: cv.getcssvar($name: 'thead-background-color');

    [role='row'] {
      border-bottom-color: cv.getcssvar($name: 'thead-row-border-color');
      border-bottom-style: cv.getcssvar($name: 'thead-row-border-style');
      border-bottom-width: cv.getcssvar($name: 'thead-row-border-width');

      &.#{config.$framework-prefix}-is-bar-row {
        border-bottom: none;

        [role='cell'] {
          padding: 0;
        }
      }
    }

    [role='columnheader'].#{config.$framework-prefix}-is-sortable,
    [role='columnheader'].#{config.$framework-prefix}-is-expandable {
      padding: 0;
    }

    [role='columnheader']:not(.#{config.$framework-prefix}-is-sorted) {
      .#{config.$framework-prefix}-icon {
        color: cv.getcssvar($name: 'scheme-50');
      }
    }

    .#{config.$framework-prefix}-table-heading {
      @extend %reset;

      align-items: center;
      display: flex;
      font-weight: cv.getcssvar($name: 'th-font-weight');
      padding: cv.getcssvar($name: 'cell-padding');
      width: 100%;

      .#{config.$framework-prefix}-icon {
        font-size: 0.75em;
        margin-right: 0.5em;
      }
    }

    button.#{config.$framework-prefix}-table-heading {
      cursor: pointer;
      padding-left: 1.25em;

      &:hover {
        background-color: cv.getcssvar($name: 'cell-hover-background-color');
      }

      &:focus {
        outline: cv.getcssvar($name: 'heading-focus-outline');
      }
    }
  }

  [role='rowgroup'] {
    [role='row'] {
      border-bottom-color: cv.getcssvar($name: 'row-border-color');
      border-bottom-style: cv.getcssvar($name: 'row-border-style');
      border-bottom-width: cv.getcssvar($name: 'row-border-width');

      &:nth-child(2n) {
        background-color: cv.getcssvar($name: 'row-alt-background-color');
      }

      &:hover {
        background-color: cv.getcssvar($name: 'row-hover-background-color');
      }

      &.#{config.$framework-prefix}-is-selected {
        background-color: cv.getcssvar($name: 'row-active-background-color');
      }
    }
  }

  &.#{config.$framework-prefix}-is-fullwidth {
    width: 100%;
  }
}

.#{config.$framework-prefix}-table-bar,
.#{config.$framework-prefix}-table-toolbar {
  // prettier-ignore
  @include cv.cssvars(
    (
      bar-background-color: cv.getcssvar($name: 'primary-color'),
      bar-color: cv.getcssvar($name: 'primary-color-invert'),
      item-font-weight: cv.getcssvar($name: 'weight-medium'),
      item-padding: 1em,
      item-hover-background-color: cv.getcssvar($name: 'primary-alt-color'),
      icon-font-size: 1em,
      icon-margin-right: 0.5em,
    ),
    $is-global-scope: false
  );

  align-items: stretch;
  background-color: cv.getcssvar($name: 'bar-background-color');
  color: cv.getcssvar($name: 'bar-color');
  display: flex;
  flex-wrap: wrap;

  &.#{config.$framework-prefix}-is-small {
    // prettier-ignore
    @include cv.cssvars(
      (
        item-padding: 0.675em 1em,
        icon-font-size: 0.75em,
      ),
      $is-global-scope: false
    );
  }
}

.#{config.$framework-prefix}-table-bar-items,
.#{config.$framework-prefix}-table-toolbar-items {
  @extend %reset;

  display: flex;
}

.#{config.$framework-prefix}-table-bar-item,
.#{config.$framework-prefix}-table-toolbar-item {
  @extend %reset;

  align-items: center;
  color: currentColor;
  display: flex;
  font-weight: cv.getcssvar($name: 'item-font-weight');
  padding: cv.getcssvar($name: 'item-padding');

  .#{config.$framework-prefix}-icon {
    font-size: cv.getcssvar($name: 'icon-font-size');

    &:first-child:not(:last-child) {
      margin-right: cv.getcssvar($name: 'icon-margin-right');
    }
  }
}

button.#{config.$framework-prefix}-table-bar-item,
button.#{config.$framework-prefix}-table-toolbar-item {
  cursor: pointer;

  &:hover {
    background-color: cv.getcssvar($name: 'item-hover-background-color');
  }
}
