@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

$small-tab-font-size: 1.125rem;
$small-tab-icon-font-size: 1rem;
$small-tabpanel-font-size: 1rem;

$slate-base-rgba50: rgba(
  map.get(
    $map: config.$neutral-colors,
    $key: 'slate-base',
  ),
  0.5
);

/// Our Tabs
/// @group Navigation
/// @name Tabs
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-tabs {
  // prettier-ignore
  @include cv.cssvars(
    (
      // Tabs
      tabs-border-bottom-color: cv.getcolor($name: slate-20),
      tabs-border-bottom-style: solid,
      tabs-border-bottom-width: 0.25rem,

      // Tab
      tab-color: cv.getcolor($name: slate-base),
      tab-focus-width: cv.getcssvar($name: 'focus-outer-size'),
      tab-font-size: 1.25rem,
      tab-line-height: 1,
      tab-padding: 1em 1.6em,
      tab-icon-font-size: 1.125rem,

      // Tabpanel
      tabpanel-font-size: 1.125rem,
      tabpanel-padding: 1.25em 1em,

      // State: hover
      tab-hover-background-color: cv.getcolor($name: slate-10),
      tab-hover-border-bottom-color: cv.getcolor($name: slate-50),
      tab-hover-color: cv.getcolor($name: slate-100),

      // State: focus
      tab-focus-border-bottom-color: cv.getcolor($name: slate-50),
      tab-focus-color: cv.getcolor($name: slate-100),

      // State: active
      tab-active-border-bottom-color: cv.getcssvar($name: primary-color),
      tab-active-color: cv.getcssvar($name: primary-color),

      // State: disabled
      tab-disabled-color: #{$slate-base-rgba50},

      // Size: small
      tab-small-font-size: #{$small-tab-font-size},
      tab-small-icon-font-size: #{$small-tab-icon-font-size},
      tabpanel-small-font-size: #{$small-tabpanel-font-size},
    ),
    $is-global-scope: false
  );

  &.#{config.$framework-prefix}-is-small {
    .#{config.$framework-prefix}-tablist {
      @include cv.cssvar(tab-font-size, cv.getcssvar($name: tab-small-font-size));
      @include cv.cssvar(tab-icon-font-size, cv.getcssvar($name: tab-small-icon-font-size));
    }

    .#{config.$framework-prefix}-tabpanel {
      @include cv.cssvar(tabpanel-font-size, cv.getcssvar($name: tabpanel-small-font-size));
    }
  }
}

.#{config.$framework-prefix}-tabscroller {
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: visible;
  padding-bottom: cv.getcssvar($name: tab-focus-width);
  padding-top: cv.getcssvar($name: tab-focus-width);
}

.#{config.$framework-prefix}-tablist {
  border-bottom-color: cv.getcssvar($name: tabs-border-bottom-color);
  border-bottom-style: cv.getcssvar($name: tabs-border-bottom-style);
  border-bottom-width: cv.getcssvar($name: tabs-border-bottom-width);
  display: flex;

  &.#{config.$framework-prefix}-is-multiline {
    flex-wrap: wrap;
  }

  &.#{config.$framework-prefix}-is-small {
    @include cv.cssvar(tab-font-size, cv.getcssvar($name: tab-small-font-size));
    @include cv.cssvar(tab-icon-font-size, cv.getcssvar($name: tab-small-icon-font-size));
  }
}

.#{config.$framework-prefix}-tab {
  font-size: cv.getcssvar($name: tab-font-size);
  list-style: none;
  margin-bottom: calc(-1 * #{cv.getcssvar($name: tabs-border-bottom-width)});
  position: relative;

  > a,
  > button {
    @include mx.reset;

    align-items: center;
    background-color: cv.getcssvar($name: tab-background-color);
    border-bottom-color: cv.getcssvar($name: tabs-border-bottom-color);
    border-bottom-style: cv.getcssvar($name: tabs-border-bottom-style);
    border-bottom-width: cv.getcssvar($name: tabs-border-bottom-width);
    color: cv.getcssvar($name: tab-color);
    cursor: pointer;
    display: flex;
    line-height: cv.getcssvar($name: tab-line-height);
    padding: cv.getcssvar($name: tab-padding);
    white-space: nowrap;

    .#{config.$framework-prefix}-icon {
      font-size: cv.getcssvar($name: tab-icon-font-size);

      &:first-child {
        margin-right: 0.6em;
      }

      &:last-child {
        margin-left: 0.6em;
      }
    }
  }

  &:hover,
  &.#{config.$framework-prefix}-is-hover {
    &:not(.#{config.$framework-prefix}-is-active) {
      @include cv.cssvars(
        (
          tab-background-color: cv.getcssvar($name: tab-hover-background-color),
          tabs-border-bottom-color: cv.getcssvar($name: tab-hover-border-bottom-color),
          tab-color: cv.getcssvar($name: tab-hover-color),
        ),
        $is-global-scope: false
      );
    }
  }

  &.#{config.$framework-prefix}-is-focused {
    &:not(.#{config.$framework-prefix}-is-active) {
      @include cv.cssvars(
        (
          tabs-border-bottom-color: cv.getcssvar($name: tab-focus-border-bottom-color),
          tab-color: cv.getcssvar($name: tab-focus-border-bottom-color),
        ),
        $is-global-scope: false
      );
    }

    box-shadow: cv.getcssvar($name: focus-box-shadow);
    z-index: 1;
  }

  &.#{config.$framework-prefix}-is-active {
    @include cv.cssvars(
      (
        tabs-border-bottom-color: cv.getcssvar($name: tab-active-border-bottom-color),
        tab-color: cv.getcssvar($name: tab-active-border-bottom-color),
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-disabled {
    @include cv.cssvars(
      (
        tab-color: cv.getcssvar($name: tab-disabled-color),
      ),
      $is-global-scope: false
    );

    pointer-events: none;
  }
}

.#{config.$framework-prefix}-tabpanel {
  font-size: cv.getcssvar($name: tabpanel-font-size);
  margin-top: calc(-1 * #{cv.getcssvar($name: 'tab-focus-width')});
  padding: cv.getcssvar($name: tabpanel-padding);

  &.#{config.$framework-prefix}-is-small {
    @include cv.cssvar(tabpanel-font-size, cv.getcssvar($name: tabpanel-small-font-size));
  }

  &:focus {
    outline: none;
  }
}
