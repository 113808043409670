@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Toast
/// @group Dialogs
/// @name Toast
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-toast {
  // prettier-ignore
  @include cv.cssvars(
    (
      toast-background-color: cv.getcssvar($name: 'scheme-main'),
      toast-border-color: cv.getcssvar($name: 'border-bis'),
      toast-border-radius: 0.125rem,
      toast-line-height: cv.getcssvar($name: 'line-height-default'),
      toast-box-shadow-color: cv.getcssvarfrommap($map: config.$shadows, $key: 'shadow-regular'),
      toast-box-shadow: (
        0 0.1em 0 cv.getcssvar($name: toast-box-shadow-color),
        inset 0 0 0 1px cv.getcssvar($name: toast-border-color)
      ),
      toast-padding: 1.25rem,

      // Status
      toast-status-border-width: 0.75em,

      // Icon
      toast-icon-margin-right: 0.75rem,
      toast-icon-dimensions: 1.5rem,

      // Content
      toast-content-padding-right: 2.5rem,

      // Heading
      toast-heading-color: cv.getcssvar($name: 'text'),
      toast-heading-font-size: 1.25em,
      toast-heading-font-weight:
        cv.getcssvarfrommap($map: config.$typography-weights, $key: 'weight-medium'),

      // Body
      toast-body-margin-left: 0.75em,
      toast-body-margin-top: 0.5em,

      // Inset
      toast-inset-border-color: cv.getcolor($name: 'slate-20'),
      toast-inset-border-style: solid,
      toast-inset-border-width: 0.125rem,
      toast-inset-margin-top: 0.5rem,
      toast-inset-max-height: 12rem,

      // Close
      toast-close-font-size: 0.75rem,
      toast-close-dimensions: 3rem,
      toast-close-spacing: 0.25rem,

      // Actions
      toast-actions-margin-top: 2rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: toast-background-color);
  border-radius: cv.getcssvar($name: toast-border-radius);
  box-shadow: cv.getcssvar($name: toast-box-shadow);
  color: cv.getcssvar($name: toast-color);
  padding: cv.getcssvar($name: toast-padding);
  position: relative;
}

.#{config.$framework-prefix}-toast-status {
  padding-left: calc(
    #{cv.getcssvar($name: toast-padding)} + #{cv.getcssvar($name: toast-status-border-width)}
  );

  &::before {
    background-color: cv.getcssvar($name: toast-status-color);
    border-bottom-left-radius: cv.getcssvar($name: toast-border-radius);
    border-top-left-radius: cv.getcssvar($name: toast-border-radius);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: cv.getcssvar($name: toast-status-border-width);
  }

  @each $name in map.keys(config.$utility-colors) {
    &.#{config.$framework-prefix}-status-#{$name} {
      @include cv.cssvars(
        (
          toast-status-color: cv.getcssvar($name: 'status-base'),
        ),
        $is-global-scope: false
      );
    }
  }
}

.#{config.$framework-prefix}-toast-main {
  display: flex;
}

.#{config.$framework-prefix}-toast-icon {
  color: cv.getcssvar($name: 'toast-status-color');
  flex-grow: 0;
  flex-shrink: 0;
  font-size: cv.getcssvar($name: toast-icon-dimensions);
  margin-right: cv.getcssvar($name: toast-icon-margin-right);
  width: cv.getcssvar($name: toast-icon-dimensions);
}

.#{config.$framework-prefix}-toast-content {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: cv.getcssvar($name: toast-content-padding-right);
}

.#{config.$framework-prefix}-toast-heading {
  color: cv.getcssvar($name: toast-heading-color);
  font-size: cv.getcssvar($name: toast-heading-font-size);
  font-weight: cv.getcssvar($name: toast-heading-font-weight);

  + .#{config.$framework-prefix}-toast-body {
    margin-top: cv.getcssvar($name: toast-body-margin-top);
  }
}

.#{config.$framework-prefix}-toast-inset {
  border-color: cv.getcssvar($name: toast-inset-border-color);
  border-style: cv.getcssvar($name: toast-inset-border-style);
  border-width: cv.getcssvar($name: toast-inset-border-width) 0;
  margin-top: cv.getcssvar($name: toast-inset-margin-top);
  max-height: cv.getcssvar($name: toast-inset-max-height);
  overflow: hidden;
  overflow-y: auto;
}

.#{config.$framework-prefix}-toast-close {
  @extend %reset;
  @extend %unselectable;

  align-items: center;
  background-color: cv.getcssvar($name: toast-close-background-color);
  border-radius: cv.getcssvar($name: 'radius-rounded');
  cursor: pointer;
  display: flex;
  font-size: cv.getcssvar($name: toast-close-font-size);
  height: cv.getcssvar($name: toast-close-dimensions);
  justify-content: center;
  position: absolute;
  right: cv.getcssvar($name: toast-close-spacing);
  top: cv.getcssvar($name: toast-close-spacing);
  width: cv.getcssvar($name: toast-close-dimensions);

  &:hover {
    @include cv.cssvar(toast-close-background-color, cv.getcssvar($name: 'scheme-rgba10'));
  }
}

.#{config.$framework-prefix}-toast-actions {
  margin-top: cv.getcssvar($name: toast-actions-margin-top);
}

// Variations
.#{config.$framework-prefix}-toast-inline {
  .#{config.$framework-prefix}-toast-heading {
    display: inline;
  }

  .#{config.$framework-prefix}-toast-body {
    display: inline;
    margin-left: cv.getcssvar($name: toast-body-margin-left);
    margin-top: 0;

    p {
      display: inline;
    }
  }
}

.#{config.$framework-prefix}-toast-banner {
  box-shadow: inset 0 0 0 1px cv.getcssvar($name: toast-border-color);
}

// Container
.#{config.$framework-prefix}-toasts {
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 27rem;
  z-index: 1000;

  .#{config.$framework-prefix}-toast {
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-name: klik-toast-bounce;
    animation-timing-function: linear;
    transform-origin: top center;
  }

  .#{config.$framework-prefix}-toast:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@keyframes klik-toast-enter {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes klik-toast-bounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(0.348, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  7.61% {
    transform: matrix3d(0.536, 0, 0, 0, 0, 0.733, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(0.638, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.41% {
    transform: matrix3d(0.738, 0, 0, 0, 0, 1.018, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(0.847, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  15.12% {
    transform: matrix3d(0.881, 0, 0, 0, 0, 1.17, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(0.976, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.92% {
    transform: matrix3d(0.98, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.72% {
    transform: matrix3d(1.038, 0, 0, 0, 0, 1.177, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(1.052, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(1.072, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  30.23% {
    transform: matrix3d(1.072, 0, 0, 0, 0, 1.043, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(1.063, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(1.044, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  50.25% {
    transform: matrix3d(1.015, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(1.011, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(0.998, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  70.27% {
    transform: matrix3d(0.997, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
