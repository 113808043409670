[data-tippy-root] {
  max-width: calc(100vw - 16px);
}
.tippy-box[data-theme='klik'] {
  background-color: #3b3b39;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
}

.tippy-box[data-theme='klik'] .tippy-content {
  padding: 12px 16px;
}

.tippy-box[data-theme='klik'] .tippy-arrow {
  color: #3b3b39;
}
