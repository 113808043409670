@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Blocks Menu
/// @group Navigation
/// @name Blocks Menu
/// @since 0.0.1
/// @author Jeremy Thomas

.#{config.$framework-prefix}-menu-blocks {
  // prettier-ignore
  @include cv.cssvars(
    (
      blocks-background-color: cv.getcssvar($name: 'primary-base'),
      blocks-subblocks-padding: 0 2rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: blocks-background-color);

  // Nested
  .#{config.$framework-prefix}-menu-blocks {
    display: none;
    padding: cv.getcssvar($name: blocks-subblocks-padding);
  }

  .#{config.$framework-prefix}-menu-item {
    &.#{config.$framework-prefix}-is-open {
      > .#{config.$framework-prefix}-menu-blocks {
        display: block;
      }
    }
  }
}

// First level
.#{config.$framework-prefix}-menu {
  > .#{config.$framework-prefix}-menu-blocks {
    > .#{config.$framework-prefix}-menu-list {
      > .#{config.$framework-prefix}-menu-item {
        > a,
        > button {
          // prettier-ignore
          @include cv.cssvars(
            (
              // Override
              menu-trigger-font-size: cv.getcssvar($name: 'size-1'),
              menu-trigger-horizontal-padding: 1.5rem
            ),
            $is-global-scope: false
          );
        }
      }
    }
  }
}
