@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Dropdown Menu
/// @group Navigation
/// @name Dropdown Menu
/// @since 0.0.1
/// @author Jeremy Thomas

// Version: Dropdown
.#{config.$framework-prefix}-menu-dropdown {
  // prettier-ignore
  @include cv.cssvars(
    (
      dropdown-background-color: cv.getcolor($name: 'light-blue-base'),
      dropdown-width: 15rem,

      // List
      dropdown-list-background-color: rgba(black, 0.1),
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: dropdown-background-color);
  width: cv.getcssvar($name: dropdown-width);

  .#{config.$framework-prefix}-menu-list {
    background-color: cv.getcssvar($name: dropdown-list-background-color);
  }

  // Nested
  .#{config.$framework-prefix}-menu-item {
    > .#{config.$framework-prefix}-menu-dropdown {
      display: none;
      left: 100%;
      position: absolute;
      top: 0;
    }

    &:hover {
      > a,
      > button {
        background-color: cv.getcssvar($name: menu-trigger-hover-background-color);
      }

      > .#{config.$framework-prefix}-menu-dropdown {
        display: block;
      }
    }

    &.#{config.$framework-prefix}-is-active {
      > a,
      > button {
        background-color: cv.getcssvar($name: menu-trigger-active-background-color);
        border-left-color: cv.getcssvar($name: menu-trigger-active-border-color);
        border-left-style: solid;
        border-left-width: cv.getcssvar($name: menu-trigger-active-border-width);
        padding-left: calc(
          #{cv.getcssvar($name: menu-trigger-horizontal-padding)} - #{cv.getcssvar(
              $name: menu-trigger-active-border-width
            )}
        );
      }
    }
  }
}
