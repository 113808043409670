@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Slider
/// @group Form
/// @name Slider
/// @since 0.0.1
/// @author Simon Groenborg
.#{config.$framework-prefix}-slider {
  @include cv.cssvars(
    (
      slider-fixed-width: 18rem,
      track-background-color: cv.getcssvar($name: 'scheme-20'),
      track-border-radius: cv.getcssvar($name: 'radius-rounded'),
      track-height: 0.25rem,
      track-active-background-color: cv.getcssvar($name: 'primary-50'),
      thumb-background-color: cv.getcssvar($name: 'primary-base'),
      thumb-box-shadow: 1px 0px 0px cv.getcssvar('shadow-color'),
      thumb-spacing: 2px,
      thumb-dimensions: 1.5rem,
      thumb-hover-background-color: cv.getcssvar($name: 'primary-80'),
      thumb-hover-dimensions: 2rem,
      thumb-active-box-shadow: -1px 0px 0px cv.getcssvar('shadow-color'),
      thumb-active-dimensions: 1.75rem,
    ),
    $is-global-scope: false
  );

  &.#{config.$framework-prefix}-is-fixed {
    width: cv.getcssvar($name: slider-fixed-width);
  }

  .#{config.$framework-prefix}-slider-item {
    align-items: center;
    display: flex;
    height: 1.5rem;
  }

  .#{config.$framework-prefix}-slider-track {
    background-color: cv.getcssvar($name: 'track-background-color');
    border-radius: cv.getcssvar($name: track-border-radius);
    height: cv.getcssvar($name: track-height);
  }

  .#{config.$framework-prefix}-slider-thumb {
    background-color: cv.getcssvar($name: 'thumb-background-color');
    border-radius: 50%;
    color: #fff;
    cursor: grab;
    height: cv.getcssvar($name: thumb-dimensions);
    text-align: center;
    width: cv.getcssvar($name: thumb-dimensions);

    &:hover {
      background-color: cv.getcssvar($name: 'thumb-hover-background-color');
      height: cv.getcssvar($name: thumb-hover-dimensions);
      margin-left: calc(
        -1 / 2 * calc(#{cv.getcssvar($name: thumb-hover-dimensions)} - #{cv.getcssvar(
                $name: thumb-dimensions
              )})
      );
      width: cv.getcssvar($name: thumb-hover-dimensions);
    }

    &:focus:not(:active) {
      box-shadow: cv.getcssvar($name: focus-box-shadow);
    }

    &:active {
      cursor: grabbing;
      height: cv.getcssvar($name: thumb-active-dimensions);
      margin-left: calc(
        -1 / 2 * calc(#{cv.getcssvar($name: thumb-active-dimensions)} - #{cv.getcssvar(
                $name: thumb-dimensions
              )})
      );
      width: cv.getcssvar($name: thumb-active-dimensions);
    }
  }

  &.#{config.$framework-prefix}-is-disabled {
    opacity: cv.getcssvar($name: 'disabled-opacity');
    pointer-events: none;
  }

  &.#{config.$framework-prefix}-is-single {
    .#{config.$framework-prefix}-slider-track-0 {
      background-color: cv.getcssvar($name: 'track-active-background-color');
    }
  }

  &.#{config.$framework-prefix}-is-range {
    .#{config.$framework-prefix}-slider-track-1 {
      background-color: cv.getcssvar($name: 'track-active-background-color');
    }
  }
}
