@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Pages Menu
/// @group Navigation
/// @name Pages Menu
/// @since 0.0.1
/// @author Jeremy Thomas

.#{config.$framework-prefix}-menu-pages {
  // prettier-ignore
  @include cv.cssvars(
    (
      pages-header-height: 4rem,
      pages-header-padding: 1rem,
      pages-back-icon-font-size: 0.75rem,
      pages-back-icon-margin-right: 0.5rem,
      pages-close-icon-font-size: 1.25rem,
    ),
    $is-global-scope: false
  );
}

.#{config.$framework-prefix}-menu-page {
  // display: none;
}

.#{config.$framework-prefix}-menu-pages-header {
  align-items: center;
  display: flex;
  height: cv.getcssvar($name: pages-header-height);
  justify-content: space-between;
  padding: cv.getcssvar($name: pages-header-padding);
}

.#{config.$framework-prefix}-menu-pages-back {
  @include mx.reset;

  align-items: center;
  cursor: pointer;
  display: flex;

  .#{config.$framework-prefix}-icon {
    font-size: cv.getcssvar($name: pages-back-icon-font-size);
    margin-right: cv.getcssvar($name: pages-back-icon-margin-right);
  }
}

.#{config.$framework-prefix}-menu-pages-close {
  @include mx.reset;

  cursor: pointer;
  font-size: cv.getcssvar($name: pages-close-icon-font-size);
  margin-left: auto;
}
