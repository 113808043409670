@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Content Switcher
/// @group Navigation
/// @name Content Switcher
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-switcher {
  // prettier-ignore
  @include cv.cssvars(
    (
      list-background-color: cv.getcssvar('scheme-10'),
      list-border-radius: 0.25rem,
      list-padding: 0.25rem,
      list-disabled-opacity: 0.5,

      item-border-radius: 0.25rem,
      item-font-size: cv.getcssvar($name: 'size-06'),
      item-font-weight: cv.getcssvar($name: 'weight-normal'),
      item-box-shadow-color: cv.getcssvar('shadow-color'),
      item-box-shadow: none,
      item-margin-right: 0.25rem,
      item-padding-horizontal: 1rem,
      item-padding-vertical: 0.5rem,
      item-line-height: 1.5rem,

      // Child: text
      text-font-size: 1.125em,

      // State: hover
      item-hover-background-color: cv.getcssvar('scheme-rgba10'),

      // State: focus
      item-focus-box-shadow: (
        0px 0px 0px 3px cv.getcssvar($name: 'focus-shadow-color'),
        inset 0px 0px 0px 2px cv.getcssvar($name: 'focus-alt-shadow-color')
      ),

      // State: selected
      item-selected-background-color: cv.getcssvar('primary-color'),
      item-selected-box-shadow: inset 0px 2px 0px cv.getcssvar($name: 'item-box-shadow-color'),
      item-selected-border-color: transparent,
      item-selected-color: cv.getcssvar('primary-color-invert'),
      item-selected-font-weight: cv.getcssvar($name: 'weight-medium'),

      // Icon
      icon-font-size: 0.875rem,
      icon-margin: 0.25rem,

      // Icon only
      item-icon-only-padding-horizontal: 0.625rem,
      item-icon-only-padding-vertical: 0.625rem,
      icon-only-font-size: 1.25rem,
    ),
    $is-global-scope: false
  );

  display: flex;

  &[disabled],
  &.#{config.$framework-prefix}-is-disabled {
    opacity: cv.getcssvar($name: 'list-disabled-opacity');
    pointer-events: none;
  }

  &.#{config.$framework-prefix}-is-small {
    @include cv.cssvars(
      (
        item-font-size: 0.875rem,
        item-padding-vertical: 0.125rem,
        item-padding-horizontal: 0.5rem,
        item-line-height: 1.25rem,
        icon-font-size: 0.75rem,
        item-icon-only-padding-horizontal: 0.375rem,
        item-icon-only-padding-vertical: 0.375rem,
        icon-only-font-size: 0.75rem,
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-large {
    @include cv.cssvars(
      (
        item-font-size: 1.125rem,
        item-padding-vertical: 1rem,
        item-padding-horizontal: 1.5rem,
        item-line-height: 1.5rem,
        icon-font-size: 1rem,
        item-icon-only-padding-horizontal: 1rem,
        item-icon-only-padding-vertical: 1rem,
        icon-only-font-size: 1.5rem,
        icon-margin: 0.375rem,
      ),
      $is-global-scope: false
    );
  }
}

.#{config.$framework-prefix}-switcher-list {
  background-color: cv.getcssvar($name: 'list-background-color');
  border-radius: cv.getcssvar($name: 'list-border-radius');
  display: flex;
  padding: cv.getcssvar($name: 'list-padding');
}

.#{config.$framework-prefix}-switcher-item {
  @extend %reset;
  @extend %unselectable;

  align-items: center;
  background-color: cv.getcssvar($name: 'item-background-color');
  border: none;
  border-radius: cv.getcssvar($name: 'item-border-radius');
  box-shadow: cv.getcssvar($name: 'item-box-shadow');
  color: cv.getcssvar($name: 'item-color');
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: cv.getcssvar($name: 'item-font-size');
  font-weight: cv.getcssvar($name: 'item-font-weight');
  justify-content: center;
  line-height: cv.getcssvar($name: 'item-line-height');
  padding: cv.getcssvar($name: 'item-padding-vertical')
    cv.getcssvar($name: 'item-padding-horizontal');
  position: relative;
  text-align: center;
  white-space: nowrap;

  .#{config.$framework-prefix}-icon {
    font-size: cv.getcssvar($name: 'icon-font-size');
  }

  .#{config.$framework-prefix}-icon-before {
    margin-right: cv.getcssvar($name: 'icon-margin');
  }

  .#{config.$framework-prefix}-icon-after {
    margin-left: cv.getcssvar($name: 'icon-margin');
  }

  &:not(:last-child) {
    margin-right: cv.getcssvar($name: 'item-margin-right');
  }

  &:hover,
  &.#{config.$framework-prefix}-is-hover {
    @include cv.cssvar(
      $name: 'item-background-color',
      $value: cv.getcssvar($name: 'item-hover-background-color')
    );

    z-index: 1;
  }

  &:focus,
  &.#{config.$framework-prefix}-is-focus {
    @include cv.cssvars(
      (
        item-box-shadow: cv.getcssvar($name: 'item-focus-box-shadow'),
      ),
      $is-global-scope: false
    );

    z-index: 1;
  }

  &:active,
  &.#{config.$framework-prefix}-is-active {
    box-shadow: none;
    transform: translateY(1px);
    z-index: 1;
  }

  &.#{config.$framework-prefix}-is-selected {
    @include cv.cssvars(
      (
        item-background-color: cv.getcssvar($name: 'item-selected-background-color'),
        item-box-shadow: cv.getcssvar($name: 'item-selected-box-shadow'),
        item-border-color: cv.getcssvar($name: 'item-selected-border-color'),
        item-color: cv.getcssvar($name: 'item-selected-color'),
        item-font-weight: cv.getcssvar($name: 'item-selected-font-weight'),
      ),
      $is-global-scope: false
    );

    &:focus {
      @include cv.cssvars(
        (
          item-box-shadow: (
            cv.getcssvar('item-focus-box-shadow'),
            cv.getcssvar('item-selected-box-shadow'),
          ),
        ),
        $is-global-scope: false
      );
    }

    z-index: 2;
  }

  &.#{config.$framework-prefix}-is-icon-only {
    @include cv.cssvars(
      (
        item-padding-horizontal: cv.getcssvar($name: 'item-icon-only-padding-horizontal'),
        item-padding-vertical: cv.getcssvar($name: 'item-icon-only-padding-vertical'),
        icon-font-size: cv.getcssvar($name: 'icon-only-font-size'),
      ),
      $is-global-scope: false
    );
  }
}

.#{config.$framework-prefix}-switcher-item-text {
  font-size: cv.getcssvar($name: 'text-font-size');
}
