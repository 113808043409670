$framework-prefix: 'klik';
$color-prefix: 'color';

// Media query breakpoints
$medium-bp: 592px;
$large-bp: 1024px;
$extra-large-bp: 1440px;
$full-width-bp: 1632px;

// TODO: we should probably add all global (not local) css variables in a map to allow for debugging
// when a variable gets overwritten when we don't want to. we can then also use @debug statements to
// find the issue.
$klik-css-vars: ();
$klik-css-vars-debug: true !default;
