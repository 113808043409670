@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Icons Menu
/// @group Navigation
/// @name Icons Menu
/// @since 0.0.1
/// @author Jeremy Thomas

.#{config.$framework-prefix}-menu-icons {
  // prettier-ignore
  @include cv.cssvars(
    (
      // Base
      icons-background-color: cv.getcssvar($name: 'primary-base'),

      // Icon Trigger
      item-margin-bottom: 1.5rem,

      // Icon Trigger
      icon-trigger-icon-font-size: cv.getcssvar($name: size-1),
      icon-trigger-text-font-size: cv.getcssvar($name: size-m2),
      icon-trigger-text-line-height: 1rem,
      icon-trigger-text-margin-top: 0.25rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: icons-background-color);

  > .#{config.$framework-prefix}-menu-list {
    > .#{config.$framework-prefix}-menu-item {
      &:not(:last-child) {
        margin-bottom: cv.getcssvar($name: item-margin-bottom);
      }

      > .#{config.$framework-prefix}-menu-dropdown {
        display: none;
        left: 100%;
        position: absolute;
        top: 0;
      }

      &:hover {
        > a,
        > button {
          background-color: cv.getcssvar($name: menu-trigger-hover-background-color);
        }

        > .#{config.$framework-prefix}-menu-dropdown {
          display: block;
        }
      }
    }
  }
}

.#{config.$framework-prefix}-menu-trigger {
  &.#{config.$framework-prefix}-is-icon {
    // prettier-ignore
    @include cv.cssvars(
      (
        menu-trigger-vertical-padding: 0.5rem,
      ),
      $is-global-scope: false
    );

    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .#{config.$framework-prefix}-icon {
      font-size: cv.getcssvar($name: icon-trigger-icon-font-size);
      margin: 0 auto;
    }

    .#{config.$framework-prefix}-text {
      font-size: cv.getcssvar($name: icon-trigger-text-font-size);
      line-height: cv.getcssvar($name: icon-trigger-text-line-height);
      margin-top: cv.getcssvar($name: icon-trigger-text-margin-top);
    }
  }
}
