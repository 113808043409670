@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Header
/// @group Navigation
/// @name Header
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-header {
  @include cv.cssvars(
    (
      header-background-color: cv.getcolor($name: 'light-blue-base'),
      header-color: cv.getcolor($name: 'white'),
      header-padding: 0 1rem,
      header-logo-dimensions: 2.5rem,
      header-logo-margin-right: 1.25rem,
      header-nav-margin-left: 0.5rem,
      header-name-size: cv.getcssvar($name: 'size-1'),
      header-name-weight: cv.getcssvar($name: 'weight-medium'),
      header-icon-font-size: 1.25rem,
      header-hover-background-color: rgba(black, 0.1),
      header-active-background-color: rgba(white, 0.1),
      header-active-border-color: cv.getcolor($name: 'white'),
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: header-background-color);
  color: cv.getcssvar($name: header-color);
  padding: cv.getcssvar($name: header-padding);
}

.#{config.$framework-prefix}-header-body {
  align-items: stretch;
  display: flex;
  height: cv.getcssvar($name: header-height);
}

.#{config.$framework-prefix}-header-brand {
  align-items: stretch;
  display: flex;
}

.#{config.$framework-prefix}-header-logo {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-right: cv.getcssvar($name: header-logo-margin-right);
  width: cv.getcssvar($name: header-logo-dimensions);

  svg {
    flex-grow: 1;
  }
}

.#{config.$framework-prefix}-header-name {
  align-items: center;
  display: flex;
  font-size: cv.getcssvar($name: header-name-size);
  font-weight: cv.getcssvar($name: header-name-weight);
  line-height: 1;
}

// Navigation
.#{config.$framework-prefix}-header-nav-item {
  display: block;
  position: relative;

  > a,
  > button {
    @include mx.reset;

    align-items: center;
    border: 0 solid transparent;
    cursor: pointer;
    display: flex;
    font-size: cv.getcssvar($name: header-nav-item-trigger-font-size);
    padding: 1rem;
    white-space: nowrap;
    width: 100%;

    > .#{config.$framework-prefix}-icon {
      font-size: 0.625em;
      margin-left: 0.5rem;
      transform-origin: center;
      transition-duration: 200ms;
      transition-property: transform;
    }
  }
}

// Dropdown
.#{config.$framework-prefix}-header-dropdown {
  background-color: cv.getcssvar($name: header-background-color);

  ul {
    background-color: cv.getcssvar($name: header-hover-background-color);
  }

  li {
    list-style: none;
  }
}

.#{config.$framework-prefix}-header-dropdown-item {
  position: relative;

  > a,
  > button {
    @include mx.reset;

    align-items: center;
    border-left: 0.25em solid transparent;
    cursor: pointer;
    display: flex;
    padding: 0.75em 1.25em 0.75em 0.75em;
    width: 100%;

    > .#{config.$framework-prefix}-icon {
      font-size: 0.625em;
      margin-left: auto;
      transform-origin: center;
      transition-duration: 200ms;
      transition-property: transform;
    }
  }

  &.#{config.$framework-prefix}-is-active {
    > a,
    > button {
      background-color: cv.getcssvar($name: header-active-background-color);
      border-left-color: cv.getcssvar($name: header-active-border-color);
    }
  }
}

// Icons
.#{config.$framework-prefix}-header-icons {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.#{config.$framework-prefix}-header-icon {
  align-items: center;
  color: currentColor;
  display: flex;
  font-size: cv.getcssvar($name: 'header-icon-font-size');

  &:not(:first-child) {
    margin-left: cv.getcssvar($name: 'header-icon-spacing');
  }

  .#{config.$framework-prefix}-icon {
    @include mx.reset;

    cursor: pointer;
    height: 2em;
    padding: 0.5em;
    width: 2em;

    &:hover {
      background-color: cv.getcssvar($name: header-hover-background-color);
    }
  }
}

// Responsiveness
@include mx.until(config.$medium-bp) {
  .#{config.$framework-prefix}-header {
    @include cv.cssvars(
      (
        header-height: 4rem,
        header-icon-spacing: 0.5rem,
        header-nav-item-trigger-font-size: 1rem,
      ),
      $is-global-scope: false
    );
  }

  .#{config.$framework-prefix}-header-nav {
    display: none;
  }
}

@include mx.from(config.$medium-bp) {
  .#{config.$framework-prefix}-header {
    @include cv.cssvars(
      (
        header-height: 5rem,
        header-logo-dimensions: 3rem,
        header-logo-margin-right: 1.5rem,
      ),
      $is-global-scope: false
    );
  }

  .#{config.$framework-prefix}-header-nav {
    align-items: stretch;
    display: flex;
    margin-left: cv.getcssvar($name: header-nav-margin-left);
  }

  .#{config.$framework-prefix}-header-nav-item {
    align-items: stretch;
    display: flex;
    > a,
    > button {
      align-items: center;
      border-width: 0.25rem 0;
      display: flex;
      justify-content: center;
    }

    &:hover {
      > a,
      > button {
        background-color: cv.getcssvar($name: header-hover-background-color);
      }

      > .#{config.$framework-prefix}-header-dropdown {
        display: block;
      }
    }

    &.#{config.$framework-prefix}-is-active {
      > a,
      > button {
        background-color: cv.getcssvar($name: header-active-background-color);
        border-bottom-color: cv.getcssvar($name: header-active-border-color);
      }
    }

    > .#{config.$framework-prefix}-header-dropdown {
      left: 0;
      top: 100%;
    }
  }

  .#{config.$framework-prefix}-header-dropdown {
    background-color: cv.getcssvar($name: header-background-color);
    display: none;
    position: absolute;
    top: 2px;
    width: 15rem;
  }

  .#{config.$framework-prefix}-header-dropdown-item {
    > a,
    > button {
      .#{config.$framework-prefix}-icon {
        transform: rotate(-90deg);
      }
    }

    // Second level
    .#{config.$framework-prefix}-header-dropdown {
      left: 100%;
      position: absolute;
      top: 0;
    }

    &:hover {
      background-color: cv.getcssvar($name: header-hover-background-color);

      .#{config.$framework-prefix}-header-dropdown {
        display: block;
      }
    }
  }

  .#{config.$framework-prefix}-header-icon.#{config.$framework-prefix}-is-burger {
    display: none;
  }
}

@include mx.from(config.$large-bp) {
  .#{config.$framework-prefix}-header {
    @include cv.cssvars(
      (
        header-padding: 0 3rem,
        header-name-size: cv.getcssvar($name: 'size-2'),
        header-icon-spacing: 2rem,
        header-nav-item-trigger-font-size: 1.25rem,
      ),
      $is-global-scope: false
    );
  }
}

@include mx.from(config.$extra-large-bp) {
  .#{config.$framework-prefix}-header {
    @include cv.cssvars(
      (
        header-name-size: cv.getcssvar($name: 'size-2'),
      ),
      $is-global-scope: false
    );
  }
}
