@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Menu
/// @group Navigation
/// @name Menu
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-menu {
  // prettier-ignore
  @include cv.cssvars(
    (
      menu-color: cv.getcssvar($name: 'primary-color-invert'),
      menu-line-height: cv.getcssvar($name: 'line-height-s'),

      // Trigger
      menu-trigger-horizontal-padding: 1em,
      menu-trigger-vertical-padding: 0.875em,
      menu-trigger-icon-font-size: 0.75em,

      // Trigger: hover
      menu-trigger-hover-background-color: rgba(black, 0.1),

      // Trigger: active
      menu-trigger-active-background-color: rgba(white, 0.1),
      menu-trigger-active-border-color: cv.getcssvar($name: 'primary-color-invert'),
      menu-trigger-active-border-width: 0.25rem,
    ),
    $is-global-scope: false
  );

  color: cv.getcssvar($name: menu-color);
  line-height: cv.getcssvar($name: menu-line-height);
}

.#{config.$framework-prefix}-menu-list {
  li {
    list-style: none;
  }
}

.#{config.$framework-prefix}-menu-item {
  position: relative;

  > a,
  > button {
    @include mx.reset;

    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: cv.getcssvar($name: 'menu-trigger-font-size');
    padding: cv.getcssvar($name: 'menu-trigger-vertical-padding')
      cv.getcssvar($name: 'menu-trigger-horizontal-padding');
    width: 100%;

    .#{config.$framework-prefix}-icon {
      font-size: cv.getcssvar($name: menu-trigger-icon-font-size);
      margin-left: auto;
      transform-origin: center;
    }

    &:hover {
      background-color: cv.getcssvar($name: menu-trigger-hover-background-color);
    }
  }

  &.#{config.$framework-prefix}-is-active {
    > a,
    > button {
      background-color: cv.getcssvar($name: menu-trigger-active-background-color);
      border-left-color: cv.getcssvar($name: menu-trigger-active-border-color);
      border-left-style: solid;
      border-left-width: cv.getcssvar($name: menu-trigger-active-border-width);
      padding-left: calc(
        #{cv.getcssvar($name: menu-trigger-horizontal-padding)} - #{cv.getcssvar(
            $name: menu-trigger-active-border-width
          )}
      );
    }
  }

  &.#{config.$framework-prefix}-is-open {
    > a,
    > button {
      .#{config.$framework-prefix}-icon {
        transform: rotate(180deg);
      }
    }
  }
}
