@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Accordion
/// @group List
/// @name Accordion
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-accordion {
  // prettier-ignore
  @include cv.cssvars(
    (
      accordion-border-color: cv.getcssvar($name: 'border-ter'),
      accordion-border-style: solid,
      accordion-border-width: 2px,
      accordion-padding: 0.5rem,

      // Child: Trigger
      trigger-background-color: cv.getcssvar($name: 'scheme-main'),
      trigger-line-height: 1.75rem,

      // Child: Icon
      icon-font-size: 0.75rem,

      // Child: Text
      text-font-size: cv.getcssvar($name: 'size-1'),
      text-font-weight: cv.getcssvar($name: 'weight-medium'),

      // Child: Panel
      panel-padding-bottom: calc(3 * #{cv.getcssvar($name: 'accordion-padding')}),

      // State: hover
      trigger-hover-background-color: cv.getcssvar($name: 'scheme-02'),

      // State: focus
      trigger-focus-box-shadow: cv.getcssvar($name: focus-outer-box-shadow),
      accordion-focus-border-color: transparent,
      trigger-focus-border-radius: 0.25rem,
    ),
    $is-global-scope: false
  );

  border-bottom-color: cv.getcssvar($name: 'accordion-border-color');
  border-bottom-style: cv.getcssvar($name: 'accordion-border-style');
  border-bottom-width: cv.getcssvar($name: 'accordion-border-width');
  border-radius: cv.getcssvar($name: 'accordion-border-radius');
  box-shadow: cv.getcssvar($name: 'accordion-box-shadow');
}

.#{config.$framework-prefix}-accordion-trigger {
  @extend %reset;
  @extend %focusable;

  align-items: center;
  background-color: cv.getcssvar($name: 'trigger-background-color');
  border-radius: cv.getcssvar($name: 'trigger-border-radius');
  border-top-color: cv.getcssvar($name: 'accordion-border-color');
  border-top-style: cv.getcssvar($name: 'accordion-border-style');
  border-top-width: cv.getcssvar($name: 'accordion-border-width');
  box-shadow: cv.getcssvar($name: 'trigger-box-shadow');
  cursor: pointer;
  display: flex;
  line-height: cv.getcssvar($name: 'trigger-line-height');
  padding: cv.getcssvar($name: 'accordion-padding');
  position: relative;
  width: 100%;

  &:hover {
    @include cv.cssvars(
      (
        trigger-background-color: cv.getcssvar('trigger-hover-background-color'),
      ),
      $is-global-scope: false
    );
  }

  &:focus {
    @include cv.cssvars(
      (
        accordion-border-color: cv.getcssvar('accordion-focus-border-color'),
        trigger-border-radius: cv.getcssvar('trigger-focus-border-radius'),
      ),
      $is-global-scope: false
    );

    z-index: 1;
  }

  .#{config.$framework-prefix}-icon {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: cv.getcssvar($name: 'icon-font-size');
    margin-left: auto;
    transform-origin: center;
    transition: 0.125s ease-in-out;
  }

  &[aria-expanded='true'] {
    .#{config.$framework-prefix}-icon {
      transform: scaleY(-1);
    }
  }

  &[aria-disabled='true'] {
    pointer-events: none;

    .#{config.$framework-prefix}-accordion-trigger-text,
    .#{config.$framework-prefix}-icon {
      opacity: 0.5;
    }
  }
}

.#{config.$framework-prefix}-accordion-trigger-text {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: cv.getcssvar($name: 'text-font-size');
  font-weight: cv.getcssvar($name: 'text-font-weight');
}

.#{config.$framework-prefix}-accordion-panel {
  padding: cv.getcssvar($name: 'accordion-padding');
  padding-bottom: cv.getcssvar($name: 'panel-padding-bottom');

  &[hidden] {
    display: none;
  }
}
