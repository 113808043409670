@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

.#{config.$framework-prefix}-grid {
  // prettier-ignore
  @include cv.cssvars(
    (
      grid-gap: cv.getcssvar($name: 'space', $fallback: 1rem),
      max-column-count: 12,
      // Minimum width of a column is X:
      // (12 * X) + (11 * gap) = 100%
      // (12 * X) = 100 - (11 * gap)
      // X = (100 / 12) - (11 / 12 * gap)
      gap-count: calc(#{cv.getcssvar($name: max-column-count)} - 1),
      column-min: (
        calc(
          calc(100% / #{cv.getcssvar($name: max-column-count)}) -
          calc(
            #{cv.getcssvar($name: gap-count)} /
            #{cv.getcssvar($name: max-column-count)} *
            #{cv.getcssvar($name: column-gap, $fallback: cv.getcssvar($name: grid-gap))}
          )
        )
      ),
    ),
    $is-global-scope: false
  );

  display: grid;
  grid-gap: cv.getcssvar($name: grid-gap);
  grid-column-gap: cv.getcssvar($name: column-gap, $fallback: cv.getcssvar($name: grid-gap));
  grid-row-gap: cv.getcssvar($name: row-gap, $fallback: cv.getcssvar($name: grid-gap));
  grid-template-columns: repeat(auto-fit, minmax(cv.getcssvar($name: column-min), 1fr));
  grid-template-rows: auto;

  @for $i from 1 through 12 {
    &.#{config.$framework-prefix}-has-#{$i}-cols {
      // prettier-ignore
      @include cv.cssvars(
        (
          max-column-count: $i,
        ),
        $is-global-scope: false
      );
    }
  }
}

.#{config.$framework-prefix}-cell {
  // prettier-ignore
  @include cv.cssvars(
    (
      column-span: 1,
      row-span: 1,
    ),
    $is-global-scope: false
  );

  grid-column-end: span cv.getcssvar($name: column-span);
  grid-column-start: cv.getcssvar($name: column-start);
  grid-row-end: span cv.getcssvar($name: row-span);
  grid-row-start: cv.getcssvar($name: row-start);

  &.#{config.$framework-prefix}-col-start-end {
    // prettier-ignore
    @include cv.cssvars(
      (
        column-start: -1,
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-row-start-end {
    // prettier-ignore
    @include cv.cssvars(
      (
        row-start: -1,
      ),
      $is-global-scope: false
    );
  }

  @for $i from 1 through 12 {
    &.#{config.$framework-prefix}-col-start-#{$i} {
      // prettier-ignore
      @include cv.cssvars(
        (
          column-start: #{$i},
        ),
        $is-global-scope: false
      );
    }

    &.#{config.$framework-prefix}-col-from-end-#{$i} {
      // prettier-ignore
      @include cv.cssvars(
        (
          column-start: #{$i * -1},
        ),
        $is-global-scope: false
      );
    }

    &.#{config.$framework-prefix}-col-span-#{$i} {
      // prettier-ignore
      @include cv.cssvars(
        (
          column-span: #{$i},
        ),
        $is-global-scope: false
      );
    }

    &.#{config.$framework-prefix}-row-start-#{$i} {
      // prettier-ignore
      @include cv.cssvars(
        (
          row-start: #{$i},
        ),
        $is-global-scope: false
      );
    }

    &.#{config.$framework-prefix}-row-from-end-#{$i} {
      // prettier-ignore
      @include cv.cssvars(
        (
          row-start: #{$i * -1},
        ),
        $is-global-scope: false
      );
    }

    &.#{config.$framework-prefix}-row-span-#{$i} {
      // prettier-ignore
      @include cv.cssvars(
        (
          row-span: #{$i},
        ),
        $is-global-scope: false
      );
    }
  }
}
