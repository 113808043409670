@use 'sass:map';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;
@use '../../config';

/// Our Input
/// @group Form
/// @name Input
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-input {
  // prettier-ignore
  @include cv.cssvars(
    (
      input-background-color: cv.getcssvar($name: 'scheme-main'),
      input-border-color: cv.getcssvar($name: 'border'),
      input-border-width: 1px,
      input-box-shadow-color: cv.getcssvar('shadow-color'),
      input-font-size: cv.getcssvar($name: 'size-0'),
      input-line-height: 1.5rem,
      input-padding-vertical: 0.75rem,
      input-padding-horizontal: 0.75rem,
      input-width: 100%,
      // Variation: select
      input-select-padding-right: 1.125rem,
      // Variation: textarea
      input-textarea-line-height: cv.getcssvar($name: 'line-height-m'),
      // State: hover
      input-hover-border-color: cv.getcssvar($name: 'scheme-60'),
      input-hover-border-width: 2px,
      // State: focus
      input-focus-border-color: cv.getcssvar($name: 'focus-outer-color'),
      input-focus-border-width: 2px,
      input-status-border-width: 2px,
      // State: disabled
      input-disabled-background-color: cv.getcssvar($name: 'scheme-05'),
      input-disabled-border-color: cv.getcssvar($name: 'scheme-40'),
      input-disabled-color: cv.getcssvar($name: 'scheme-base'),
      // Size: small
      input-small-font-size: cv.getcssvar($name: 'size-m1'),
      input-small-padding-vertical: 0.25rem,
      input-small-padding-horizontal: 0.5rem,
      // Size: large
      input-large-font-size: cv.getcssvar($name: 'size-05'),
      input-large-padding-vertical: 1.25rem,
      input-large-padding-horizontal: 1rem,
    ),
    $is-global-scope: false
  );

  background-color: cv.getcssvar($name: 'input-background-color');
  border: cv.getcssvar($name: 'input-border-width') solid cv.getcssvar($name: 'input-border-color');
  border-radius: 0.25rem;
  box-shadow: inset 0 2px 0 cv.getcssvar($name: 'input-box-shadow-color');
  color: cv.getcssvar($name: 'input-color');
  font-size: cv.getcssvar($name: 'input-font-size');
  line-height: cv.getcssvar($name: 'input-line-height');
  outline: none;
  padding: calc(
      #{cv.getcssvar($name: 'input-padding-vertical')} - #{cv.getcssvar(
          $name: 'input-border-width'
        )}
    )
    calc(
      #{cv.getcssvar($name: 'input-padding-horizontal')} - #{cv.getcssvar(
          $name: 'input-border-width'
        )}
    );
  width: cv.getcssvar($name: 'input-width');

  @include mx.placeholder() {
    color: cv.getcssvar($name: 'scheme-base');
  }

  &.#{config.$framework-prefix}-is-small {
    @include cv.cssvars(
      (
        input-font-size: cv.getcssvar($name: 'input-small-font-size'),
        input-padding-vertical: cv.getcssvar($name: 'input-small-padding-vertical'),
        input-padding-horizontal: cv.getcssvar($name: 'input-small-padding-horizontal'),
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-large {
    @include cv.cssvars(
      (
        input-font-size: cv.getcssvar($name: 'input-large-font-size'),
        input-padding-vertical: cv.getcssvar($name: 'input-large-padding-vertical'),
        input-padding-horizontal: cv.getcssvar($name: 'input-large-padding-horizontal'),
      ),
      $is-global-scope: false
    );
  }

  &.#{config.$framework-prefix}-is-flexible {
    padding: calc(0.75em - #{cv.getcssvar($name: 'input-border-width')});

    &.#{config.$framework-prefix}-is-small {
      padding: calc(0.25em - #{cv.getcssvar($name: 'input-border-width')})
        calc(0.5em - #{cv.getcssvar($name: 'input-border-width')});
    }

    &.#{config.$framework-prefix}-is-large {
      padding: calc(0.75em - #{cv.getcssvar($name: 'input-border-width')});
    }
  }

  &:hover,
  &.#{config.$framework-prefix}-is-hover {
    @include cv.cssvars(
      (
        input-border-color: cv.getcssvar($name: 'input-hover-border-color'),
        input-border-width: cv.getcssvar($name: 'input-hover-border-width'),
      ),
      $is-global-scope: false
    );

    box-shadow: none;
  }

  &:focus,
  &.#{config.$framework-prefix}-is-focus {
    @include cv.cssvars(
      (
        input-border-color: cv.getcssvar($name: 'input-focus-border-color'),
        input-border-width: cv.getcssvar($name: 'input-focus-border-width'),
      ),
      $is-global-scope: false
    );

    box-shadow: none;
  }

  &[disabled],
  &.#{config.$framework-prefix}-is-disabled {
    @include cv.cssvars(
      (
        input-background-color: cv.getcssvar($name: 'input-disabled-background-color'),
        input-border-color: cv.getcssvar($name: 'input-disabled-border-color'),
        input-color: cv.getcssvar($name: 'input-disabled-color'),
      ),
      $is-global-scope: false
    );

    opacity: 0.5;
    pointer-events: none;
  }

  &.#{config.$framework-prefix}-status-warning,
  &.#{config.$framework-prefix}-status-error {
    @include cv.cssvar(input-border-color, cv.getcssvar($name: 'status-base'));
    @include cv.cssvar(input-border-width, cv.getcssvar($name: 'input-status-border-width'));

    box-shadow: none;
  }

  &.#{config.$framework-prefix}-is-select {
    align-items: center;
    box-shadow: 0 1px 0 cv.getcssvar($name: 'input-box-shadow-color');
    cursor: pointer;
    display: flex;
    min-height: calc(calc(2 * #{cv.getcssvar($name: input-padding-vertical)}) + 2rem);
    // prettier-ignore
    padding-right: calc(
      #{cv.getcssvar($name: 'input-select-padding-right')} - #{cv.getcssvar($name: 'input-border-width')}
    );

    .#{config.$framework-prefix}-input-content {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .#{config.$framework-prefix}-icon {
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0.75rem;
      margin-left: 0.5em;
    }

    .#{config.$framework-prefix}-input-hidden {
      background: 0px center;
      border-color: initial;
      border-image: initial;
      border-style: initial;
      border-width: 0px;
      color: transparent;
      font-size: inherit;
      left: -100px;
      opacity: 0;
      outline: 0px;
      padding: 0px;
      position: relative;
      transform: scale(0);
      width: 1px;
    }
  }
}

textarea.#{config.$framework-prefix}-input {
  // prettier-ignore
  @include cv.cssvars(
    (
      input-line-height: cv.getcssvar($name: 'input-textarea-line-height'),
    ),
    $is-global-scope: false
  );

  min-height: 4.5em;
  resize: vertical;
}
