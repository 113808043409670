/* eslint-disable */
@use '../config' as config;
@use '../helpers/css-variables' as cv;

@mixin theme() {
  // prettier-ignore
  @include cv.cssvars(
    (
      magenta-canary: true,

      // Primary color
      primary-color: cv.getcolor($name: 'magenta-base'),
      primary-color-invert: cv.getcolor($name: 'white'),
      primary-alt-color: cv.getcolor($name: 'magenta-90'),
      primary-alt-color-invert: cv.getcolor($name: 'white'),

      // Get all the shades of 1 primary color
      primary-02: cv.getcolor($name: 'magenta-02'),
      primary-05: cv.getcolor($name: 'magenta-05'),
      primary-10: cv.getcolor($name: 'magenta-10'),
      primary-20: cv.getcolor($name: 'magenta-20'),
      primary-30: cv.getcolor($name: 'magenta-30'),
      primary-40: cv.getcolor($name: 'magenta-40'),
      primary-50: cv.getcolor($name: 'magenta-50'),
      primary-60: cv.getcolor($name: 'magenta-60'),
      primary-base: cv.getcolor($name: 'magenta-base'),
      primary-80: cv.getcolor($name: 'magenta-80'),
      primary-90: cv.getcolor($name: 'magenta-90'),
      primary-100: cv.getcolor($name: 'magenta-100'),

      // CTA color (for buttons mainly)
      cta-color: cv.getcolor($name: 'magenta-base'),
      cta-color-invert: cv.getcolor($name: 'white'),
      cta-alt-color: cv.getcolor($name: 'magenta-80'),
      cta-alt-color-invert: cv.getcolor($name: 'white'),

      focus-outer-color: orange,
    ),
    $is-global-scope: false
  );
}
