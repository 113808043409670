@use 'sass:map';
@use '../../config';
@use '../../helpers/css-variables' as cv;
@use '../../helpers/mixins' as mx;

/// Our Tag
/// @group Elements
/// @name Tag
/// @since 0.0.1
/// @author Jeremy Thomas
.#{config.$framework-prefix}-tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -0.5em;

  .#{config.$framework-prefix}-tag {
    margin-bottom: 0.5em;

    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}

.#{config.$framework-prefix}-tag {
  // prettier-ignore
  @include cv.cssvars(
    (
      tag-background-color: cv.getcssvar($name: 'primary-10'),
      tag-border-radius: cv.getcssvar($name: 'radius-rounded'),
      tag-color: cv.getcssvar($name: 'primary-base'),
      tag-font-size: cv.getcolor($name: 'size-0'),
      tag-font-weight: cv.getcssvar($name: 'weight-medium'),
      tag-line-height: 1rem,
      tag-padding-horizontal: 0.625rem,
      tag-padding-vertical: 0.5rem,
      tag-icon-size: 0.625rem,

      // State: hover
      tag-hover-background-color: cv.getcssvar($name: 'primary-30'),
      tag-hover-color: cv.getcssvar($name: 'primary-80'),

      // State: active
      tag-active-background-color: cv.getcssvar($name: 'primary-30'),

      // Size: small
      tag-small-font-size: cv.getcssvar($name: 'size-m1'),
      tag-small-padding-horizontal: 0.5rem,
      tag-small-padding-vertical: 0.25rem,
      tag-small-icon-size: 0.5rem,

      // Size: large
      tag-large-font-size: cv.getcssvar($name: 'size-05'),
      tag-large-line-height: 1.125rem,
      tag-large-padding-horizontal: 0.75rem,
      tag-large-padding-vertical: 0.625rem,
      tag-large-icon-size: 0.75rem,
    ),
    $is-global-scope: false
  );
  @extend %reset;

  align-items: stretch;
  border-radius: cv.getcssvar($name: 'tag-border-radius');
  display: inline-flex;
  outline: none;
  vertical-align: top;

  &.#{config.$framework-prefix}-is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .#{config.$framework-prefix}-tags.#{config.$framework-prefix}-are-small &,
  &.#{config.$framework-prefix}-is-small {
    @include cv.cssvars(
      (
        tag-font-size: cv.getcssvar($name: 'tag-small-font-size'),
        tag-padding-horizontal: cv.getcssvar($name: 'tag-small-padding-horizontal'),
        tag-padding-vertical: cv.getcssvar($name: 'tag-small-padding-vertical'),
        tag-icon-size: cv.getcssvar($name: 'tag-small-icon-size'),
      ),
      $is-global-scope: false
    );
  }

  .#{config.$framework-prefix}-tags.#{config.$framework-prefix}-are-large &,
  &.#{config.$framework-prefix}-is-large {
    @include cv.cssvars(
      (
        tag-font-size: cv.getcssvar($name: 'tag-large-font-size'),
        tag-line-height: cv.getcssvar($name: 'tag-large-line-height'),
        tag-padding-horizontal: cv.getcssvar($name: 'tag-large-padding-horizontal'),
        tag-padding-vertical: cv.getcssvar($name: 'tag-large-padding-vertical'),
        tag-icon-size: cv.getcssvar($name: 'tag-large-icon-size'),
      ),
      $is-global-scope: false
    );
  }
}

.#{config.$framework-prefix}-tag-item {
  @extend %reset;

  align-items: center;
  background-color: cv.getcssvar($name: 'tag-background-color');
  border-radius: cv.getcssvar($name: 'tag-border-radius');
  color: cv.getcssvar($name: 'tag-color');
  display: inline-flex;
  font-size: cv.getcssvar($name: 'tag-font-size');
  font-weight: cv.getcssvar($name: 'tag-font-weight');
  justify-content: center;
  line-height: cv.getcssvar($name: 'tag-line-height');
  padding: cv.getcssvar($name: 'tag-padding-vertical') cv.getcssvar($name: 'tag-padding-horizontal');
  position: relative;
  text-align: center;
  vertical-align: top;

  .#{config.$framework-prefix}-icon {
    height: cv.getcssvar($name: 'tag-icon-size');
    width: cv.getcssvar($name: 'tag-icon-size');
  }

  &:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: calc(#{cv.getcssvar($name: 'tag-padding-horizontal')} / 2);
  }

  &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: calc(#{cv.getcssvar($name: 'tag-padding-horizontal')} / 2);
  }
}

a.#{config.$framework-prefix}-tag-item,
button.#{config.$framework-prefix}-tag-item {
  cursor: pointer;

  &:hover {
    @include cv.cssvar(
      $name: 'tag-background-color',
      $value: cv.getcssvar($name: 'tag-hover-background-color')
    );
    @include cv.cssvar($name: 'tag-color', $value: cv.getcssvar($name: 'tag-hover-color'));
  }

  &:focus {
    &:not(:active) {
      box-shadow: cv.getcssvar($name: focus-box-shadow);
      z-index: 1;
    }
  }

  &:active {
    @include cv.cssvar(
      $name: 'tag-background-color',
      $value: cv.getcssvar($name: 'tag-active-background-color')
    );

    transform: translateY(1px);
  }
}
