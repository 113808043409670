@use '../../config';
@use '../../helpers/css-variables';

@each $name, $value in config.$typography-sizes {
  .#{config.$framework-prefix}-text-#{$name} {
    font-size: css-variables.getcssvar($name: $name);
  }
}

@each $name, $value in config.$typography-weights {
  .#{config.$framework-prefix}-text-#{$name} {
    font-weight: css-variables.getcssvar($name: $name);
  }
}
